const { instance, member } = require(".");

//회원 가입후 이메일 인증
function emailComfirm(query) {
  return instance.post(`comfirm/sign-up${query}`);
}

//로그인
function loginUser(userData) {
  return instance.post("login", userData);
}

//내정보 조회
function getMyPage() {
  return member.get("");
}

//내정보 수정
function setMyPage(data) {
  return member.post("modify", data);
}

//내정보 조회
function setMyPw(data) {
  return member.put("pw", data);
}

//로그인 - 비밀번호 찾기
function sendCode(data) {
  return member.post("fg/pw", data);
}

//로그인 - 비밀번호 수정
function setMyNewPw(data) {
  return member.post("cf/pw", data);
}

export {
  emailComfirm,
  loginUser,
  getMyPage,
  setMyPage,
  setMyPw,
  sendCode,
  setMyNewPw,
};
