<template>
  <span class="icon">
    <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.25H16.75M8 19.25H13.475M10.5 6.5H15.5C18 6.5 18 5.25 18 4C18 1.5 16.75 1.5 15.5 1.5H10.5C9.25 1.5 8 1.5 8 4C8 6.5 9.25 6.5 10.5 6.5Z"
        class="stroke"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 4.0249C22.1625 4.2499 24.25 5.7874 24.25 11.4999V18.9999C24.25 23.9999 23 26.4999 16.75 26.4999H9.25C3 26.4999 1.75 23.9999 1.75 18.9999V11.4999C1.75 5.7999 3.8375 4.2499 8 4.0249"
        class="stroke"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.stroke {
  stroke: #7a7a7a;
}
</style>
