<template>
  <span class="icon">
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.7749H25.9875"
        class="stroke"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.944 23.9721H6.55972C2.12444 23.9721 1 22.7822 1 18.0364V6.92217C1 2.62251 1.92454 1.24338 5.41029 1.02704C5.76012 1.01352 6.14743 1 6.55972 1H20.4403C24.8756 1 26 2.18984 26 6.93569V12.8984"
        class="stroke"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.3294 22.1058L19.6932 19.2394M22.3019 19.2799L19.6532 22.1329M5.99756 17.8873H10.9951M20.9901 25.9999C22.3155 25.9999 23.5866 25.43 24.5238 24.4158C25.461 23.4015 25.9876 22.0259 25.9876 20.5915C25.9876 19.1571 25.461 17.7814 24.5238 16.7672C23.5866 15.7529 22.3155 15.1831 20.9901 15.1831C19.6646 15.1831 18.3935 15.7529 17.4563 16.7672C16.5191 17.7814 15.9926 19.1571 15.9926 20.5915C15.9926 22.0259 16.5191 23.4015 17.4563 24.4158C18.3935 25.43 19.6646 25.9999 20.9901 25.9999Z"
        class="stroke"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.icon {
  .stroke {
    stroke: #b0b0b1;
  }
}

.active {
  .icon {
    .stroke {
      animation: fade 0.25s linear alternate;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fade {
  from {
    stroke: #b0b0b1;
  }
  to {
    stroke: $mainColor;
  }
}
</style>
