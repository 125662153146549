<template>
  <li class="list" :class="$store.state.lang">
    <h3>
      <button
        class="title"
        :class="item.type === 'logout' && 'logout'"
        @click="menuEvent(item.id, item.type === 'logout')"
      >
        <dx-icon v-if="item.type === 'dx'" />
        <payment-icon v-if="item.type === 'payment'" />
        <service-icon v-if="item.type === 'service'" />
        <myprofile-icon v-if="item.type === 'myprofile'" />
        <logout-icon v-if="item.type === 'logout'" />
        {{ item.title }}
      </button>
    </h3>
    <main-two-depth :list="item.depth" />
  </li>
</template>

<script>
import DxIcon from "../styled/icon/menu/DxIcon.vue";
import LogoutIcon from "../styled/icon/menu/LogoutIcon.vue";
import MyprofileIcon from "../styled/icon/menu/MyprofileIcon.vue";
import PaymentIcon from "../styled/icon/menu/PaymentIcon.vue";
import ServiceIcon from "../styled/icon/menu/ServiceIcon.vue";
import MainTwoDepth from "./MainTwoDepth.vue";
export default {
  components: {
    DxIcon,
    ServiceIcon,
    MyprofileIcon,
    MainTwoDepth,
    PaymentIcon,
    LogoutIcon,
  },
  props: { item: Object },
  methods: {
    menuEvent(id, logout) {
      if (logout) {
        this.appLogout();
      } else {
        this.$emit("menu", id);
      }
    },
    async appLogout() {
      try {
        await this.$store.dispatch("SIGNOUT");
        this.$router.push("login");
      } catch (error) {
        this.$store.state.loading = false;
        this.$store.state.error = error.response.data?.code;
      }
    },
  },
};
</script>

<style lang="scss">
.menuList {
  .list {
    position: relative;
    &.active {
      z-index: 10;
    }
    h3 {
      width: 50%;
      padding-left: 20px;
    }
    .title {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 16px;
      border-radius: 25px 0px 0px 25px;
      margin-bottom: 3px;
      text-align: left;
      @include boxTxtStyle(
        $fz: 18px,
        $ls: -0.9px,
        $fw: 500,
        $lh: 24px,
        $c: #7a7a7a
      );
      .icon {
        display: flex;
        margin-right: 16px;
      }
      transition: background 0.25s ease;
    }
    .logout {
      @include boxTxtStyle(
        $fz: 18px,
        $ls: -0.9px,
        $fw: 500,
        $lh: 24px,
        $c: $mainColor
      );
    }
    &.active {
      .title {
        font-weight: 700;
        color: #000;
        background-color: #fff;
      }
      .stroke {
        stroke: #000;
      }
    }
  }
}

$mobile: 430px;

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@include mobile {
  .menuList .list.en .title .icon,
  .menuList .list.ru .title .icon {
    display: none;
  }
}
</style>
