<template>
  <span class="icon">
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.1123H26"
        class="stroke"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.95 22.2375H6.5625C2.125 22.2375 1 21.1375 1 16.75V6.475C1 2.5 1.925 1.225 5.4125 1.025C5.7625 1.0125 6.15 1 6.5625 1H20.45C24.8875 1 26.0125 2.1 26.0125 6.4875V12"
        class="stroke"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.34 20.5123L19.7025 17.8623M22.3125 17.8998L19.6625 20.5373M6 16.6123H11M21 24.1123C22.3261 24.1123 23.5979 23.5855 24.5355 22.6478C25.4732 21.7102 26 20.4384 26 19.1123C26 17.7862 25.4732 16.5145 24.5355 15.5768C23.5979 14.6391 22.3261 14.1123 21 14.1123C19.6739 14.1123 18.4021 14.6391 17.4645 15.5768C16.5268 16.5145 16 17.7862 16 19.1123C16 20.4384 16.5268 21.7102 17.4645 22.6478C18.4021 23.5855 19.6739 24.1123 21 24.1123Z"
        class="stroke"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.stroke {
  stroke: #7a7a7a;
}
</style>
