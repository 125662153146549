import i18n from "@/i18n";
import store from "@/store";
import { createWebHistory, createRouter } from "vue-router";
const {
  global: { t },
} = i18n;

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //splash screen
    {
      path: "/",
      name: "Index",
      component: () => import("@/views/SplashScreenView"),
      meta: { homeTo: true },
    },
    //splash screen - login
    {
      path: "/onboarding",
      name: "onboarding",
      component: () => import("@/views/OnBoardingLoginView"),
      meta: { homeTo: true },
    },
    //login
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView"),
      meta: { homeTo: true },
      children: [
        {
          path: "/login",
          component: () => import("@/components/login/LoginContent.vue"),
        },
        {
          path: "pwfind",
          name: "pwfind",
          component: () => import("@/components/login/LoginPwFind.vue"),
        },
        {
          path: "newpw",
          name: "newpw",
          component: () => import("@/components/login/LoginNewPw.vue"),
        },
        {
          path: "verify",
          name: "verify",
          component: () => import("@/components/login/LoginVerifyCode.vue"),
        },
      ],
    },
    // join
    {
      path: "/join",
      name: "join",
      redirect: "/join/main",
      component: () => import("@/views/JoinView"),
      meta: { homeTo: true },
      children: [
        {
          path: "/join/main",
          name: "join",
          component: () => import("@/components/join/JoinForm.vue"),
        },
        {
          path: "/join/verify/:email",
          name: "joinVerify",
          component: () => import("@/components/join/JoinVerifyCode.vue"),
        },
      ],
    },
    //home
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/HomeView"),
      meta: { authRequired: true },
    },
    //zoom
    {
      path: "/zoom",
      name: "zoom",
      component: () => import("@/views/ZoomView"),
      meta: { authRequired: true },
      children: [
        {
          path: "/zoom/:id",
          name: "zoomBooking",
        },
      ],
    },
    //zoom
    {
      path: "/voice",
      name: "voice",
      component: () => import("@/views/ZoomView"),
      meta: { authRequired: true },
      children: [
        {
          path: "/voice/:id",
          name: "voiceBooking",
        },
      ],
    },
    //metaverse
    {
      path: "/metaverse",
      name: "metaverse",
      component: () => import("@/views/MetaverseView"),
      meta: { authRequired: true },
      children: [
        {
          path: "/metaverse/:id",
          name: "metaverseBooking",
        },
      ],
    },
    //결제내역
    {
      path: "/payment",
      name: "payment",
      component: () => import("@/views/payment/PaymentView.vue"),
      meta: { authRequired: true },
      redirect: "/payment/1",
      children: [
        {
          path: "/payment/:id",
          name: "payment",
        },
      ],
    },
    //마이페이지
    {
      path: "/mypage",
      name: "mypage",
      component: () => import("@/views/myprofile/MyPageView.vue"),
      meta: { authRequired: true },
    },
    //내 프로필
    {
      path: "/myprofile/setting",
      name: "setting",
      component: () => import("@/views/myprofile/MyProfileView.vue"),
      meta: { authRequired: true },
    },
    //내 프로필 - 비밀번호 변경
    {
      path: "/myprofile/pwchange",
      name: "pwchange",
      component: () => import("@/views/myprofile/PwChangeView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/myprofile/pwchange",
          name: "newpw",
          component: () =>
            import(
              "@/components/containers/myprofile/pwChange/CurrentPwContainer.vue"
            ),
        },
      ],
    },
    //고객센터 - 공지사항
    {
      path: "/cs/notice",
      name: "notice",
      redirect: "/cs/notice/1",
      component: () => import("@/views/cs/NoticeView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/cs/notice/:id",
          name: "notice",
        },
      ],
    },
    //고객센터 - Faq
    {
      path: "/cs/faq",
      name: "faq",
      redirect: "/cs/faq/1",
      component: () => import("@/views/cs/FaqView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/cs/faq/:id",
          name: "faq",
        },
      ],
    },
    //의료진 검색
    {
      path: "/dx/consultApply",
      name: "consultApply",
      redirect: "/dx/consultApply/1",
      component: () => import("@/views/dx/ConsultApplyView.vue"),
      meta: { authRequired: true },
      // eslint-disable-next-line
      children: [
        {
          path: "/dx/consultApply/:id",
          name: "consultApply",
        },
        {
          path: "/dx/consultApply",
          name: "consultApplySearch",
          props: (route) => ({ query: route.query.q }),
        },
      ],
    },
    //의료진 예약
    {
      path: "/dx/consultReserve",
      name: "consultReserve",
      component: () => import("@/views/dx/ConsultReserveView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/consultReserve/:id",
          name: "consultReserveId",
        },
      ],
      // eslint-disable-next-line
      beforeEnter: function (to, from, next) {
        if (store.state.docProfile === null) {
          alert(t("common.expiration"));
          router.push("/home");
        } else {
          next();
        }
      },
    },
    //상담관리 - 상담신청 확인,
    {
      path: "/dx/consultApply/confirm",
      name: "applyConfirm",
      component: () => import("@/views/dx/ConsultConfirmView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/consultApply/confirm/:id",
          name: "applyConfirmId",
        },
      ],
      beforeEnter: function (to, from, next) {
        if (store.state.docProfile === null) {
          alert(t("common.expiration"));
          router.push("/home");
        } else {
          next();
        }
      },
    },
    //상담관리 - 상담대기
    {
      path: "/dx/wait/list",
      name: "wait",
      redirect: "/dx/wait/list/1",
      component: () => import("@/views/dx/DxView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/wait/list/:id",
          name: "waitList",
          component: () => import("@/components/dx/DxContent.vue"),
        },
        {
          path: "/dx/wait/list/:id/:detail",
          name: "waitDetail",
          component: () => import("@/components/dx/DxDetail.vue"),
        },
      ],
    },
    //상담관리 - 상담확정
    {
      path: "/dx/confirm/list",
      name: "confirm",
      redirect: "/dx/confirm/list/1",
      component: () => import("@/views/dx/DxView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/confirm/list/:id",
          name: "confirmList",
          component: () => import("@/components/dx/DxContent.vue"),
        },
        {
          path: "/dx/confirm/list/:id/:detail",
          name: "confirmUserId",
          component: () => import("@/components/dx/DxDetail.vue"),
        },
      ],
    },
    //상담관리 - 상담완료
    {
      path: "/dx/completed/list",
      name: "completed",
      redirect: "/dx/completed/list/1",
      component: () => import("@/views/dx/DxView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/completed/list/:id",
          name: "completedList",
          component: () => import("@/components/dx/DxContent.vue"),
        },
        {
          path: "/dx/completed/list/:id/:detail",
          name: "completedUserId",
          component: () => import("@/components/dx/DxDetail.vue"),
        },
      ],
    },
    //상담관리 - 상담반려
    {
      path: "/dx/reject/list",
      name: "reject",
      redirect: "/dx/reject/list/1",
      component: () => import("@/views/dx/DxView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/dx/reject/list/:id",
          name: "rejectList",
          component: () => import("@/components/dx/DxContent.vue"),
        },
        {
          path: "/dx/reject/list/:id/:detail",
          name: "rejectUserId",
          component: () => import("@/components/dx/DxDetail.vue"),
        },
      ],
    },
    {
      path: "/:pathMatch(.*)",
      name: "not-found",
      component: () => import("@/views/ErrorView"),
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(function (to, from, next) {
  if (store.state.error === -999) {
    // router.push("/error");
    store.state.error = null;
  }
  if (
    !store.state.token &&
    to.matched.some(function (routeInfo) {
      return routeInfo.meta.authRequired;
    })
  ) {
    // auth
    store.state.reLogin = true;
  } else {
    if (
      to.matched.some(function (routeInfo) {
        return routeInfo.meta.homeTo;
      }) &&
      store.state.token &&
      store.state.error !== -968
    ) {
      router.push("/home");
      if (store.state.reLogin) next();
    } else {
      if (store.state.token && store.state.error === -968) {
        store.state.reLogin = true;
        next();
      }
      next();
    }
  }
});
export default router;
