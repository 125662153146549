<template>
  <span class="icon">
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="28.6667"
        cy="28.6667"
        r="26.6667"
        stroke="#199A8E"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="27" y="14" width="3" height="23" rx="1.5" fill="#199A8E" />
      <circle cx="28.5" cy="41.5" r="1.5" fill="#199A8E" />
      <path
        d="M62 61.9995L47.5 47.4995"
        stroke="#199A8E"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
