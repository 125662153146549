<template>
  <button class="close" @click="close">
    <span class="h">{{ $t("common.close") }}</span>
  </button>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  right: 30px;
  top: 50px;
  width: 33px;
  height: 33px;
  z-index: 11;
}
.close:before,
.close:after {
  position: absolute;
  top: 0;
  left: 15px;
  content: " ";
  height: 35px;
  width: 1.5px;
  background-color: $mainColor;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
</style>
