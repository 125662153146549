import { userPoolData } from "@/utils/common";

var AmazonCognitoIdentity = require("amazon-cognito-identity-js");

export default {
  //token
  setUserInfo(state, data) {
    state.userInfo = data;
  },
  clearUsername(state) {
    state.username = "";
  },
  setToken(state, token) {
    state.token = token;
  },
  clearToken(state) {
    state.token = "";
  },
  //home
  getHomeConfirmedCnt(state, cnt) {
    state.home.confirmed = cnt;
  },
  getHomeMyInfo(state, info) {
    state.home.myInfo = info;
  },
  getHomeMyProfile(state, info) {
    state.home.myProfile = info;
  },

  getHomeReqCnt(state, cnt) {
    state.home.requested = cnt;
  },

  //aws
  signOut(state) {
    // state.cognitoUser.signOut();
    state.sessionToken = null;
    state.authenticated = false;
    state.username = "";
    state.userPool = [];
  },
  setAttributes(state, attributes) {
    state.attributes = attributes;
    state.username = state.attributes.filter(function (Obj) {
      return Obj.Name === "email";
    })[0].Value;
  },
  setUsername(state, payload) {
    state.username = payload;
  },
  signIn(state) {
    state.authenticated = true;
  },
  setUserPool(state) {
    state.userPool = new AmazonCognitoIdentity.CognitoUserPool(userPoolData);
  },
  setTokens(state, payload) {
    state.tokens.accessToken = payload.getAccessToken().getJwtToken();
    state.tokens.idToken = payload.getIdToken().getJwtToken();
    state.tokens.refreshToken = payload.getRefreshToken().getToken();
  },
  setCognitoUser(state, payload) {
    state.cognitoUser = payload;
  },
  setCognitoDetails(state, authData) {
    state.authDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authData
    );
    state.userData = { Username: authData.Username, Pool: state.userPool };
    state.cognitoUser = new AmazonCognitoIdentity.CognitoUser(state.userData);
  },
  setError(state, payload) {
    state.errcode = payload;
  },
  clearError(state) {
    state.errcode = "";
  },
};
