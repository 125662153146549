<template>
  <quick-menu-item :list="quickMenu" />
</template>

<script>
import QuickMenuItem from "./QuickMenuItem.vue";
//진료관리
const menu1 = "/dx/";

//스케줄 관리
const menu2 = "/payment";

//고객센터
const menu3 = "/cs/";

export default {
  components: { QuickMenuItem },
  data() {
    return {
      quickMenu: [
        {
          title: "home",
          link: "/home",
        },
        {
          title: "dx",
          link: `${menu1}wait/list`,
        },
        {
          title: "payment",
          link: `${menu2}`,
        },
        {
          title: "cs",
          link: `${menu3}notice`,
        },
        {
          title: "myprofile",
          link: "/mypage",
        },
      ],
    };
  },
};
</script>

<style></style>
