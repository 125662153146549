<template>
  <span class="icon">
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.47313 2.44444H9.89253C10.5726 2.44444 11.1291 1.89444 11.1291 1.22222C11.1291 0.55 10.5726 0 9.89253 0H2.47313C1.11291 0 0 1.1 0 2.44444V19.5556C0 20.9 1.11291 22 2.47313 22H9.89253C10.5726 22 11.1291 21.45 11.1291 20.7778C11.1291 20.1056 10.5726 19.5556 9.89253 19.5556H2.47313V2.44444Z"
        fill="#199A8E"
      />
      <path
        d="M19.8249 10.5718L16.3749 7.16178C16.2889 7.07446 16.1785 7.01448 16.0578 6.98953C15.9371 6.96458 15.8117 6.9758 15.6975 7.02173C15.5833 7.06767 15.4855 7.14625 15.4168 7.2474C15.348 7.34856 15.3113 7.46769 15.3115 7.58956V9.77734H6.65551C5.9754 9.77734 5.41895 10.3273 5.41895 10.9996C5.41895 11.6718 5.9754 12.2218 6.65551 12.2218H15.3115V14.4096C15.3115 14.9596 15.9792 15.2284 16.3626 14.8373L19.8126 11.4273C20.0599 11.1951 20.0599 10.804 19.8249 10.5718Z"
        fill="#199A8E"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
