<template>
  <!-- my profile -->
  <div class="profile myprofile" v-if="profile">
    <div class="img" :class="!imgUrl && 'bd'">
      <img v-if="imgUrl" :src="imgUrl" :alt="name" />
      <div v-else class="noimg"></div>
    </div>
    <div class="profileWrap">
      <h4 class="title">{{ name }}</h4>
      <p v-if="text" class="text">{{ text }}</p>
    </div>
  </div>
  <!-- profile list -->
  <li class="profile" v-else>
    <div class="img">
      <img v-if="imgUrl" :src="imgUrl" :alt="name" />
      <div v-else class="noimg"></div>
    </div>
    <div class="profileWrap" :class="type === 'type2' && 'type2'">
      <h4 class="title">{{ name }}</h4>
      <div class="txtWrap">
        <strong class="text">{{ text }}</strong>
        <span v-if="type === 'type2'" class="date">
          {{ $filters.formatDate(listDate, $i18n.locale) }}</span
        >
      </div>
    </div>
    <icon-wrap v-if="type === 'type2'" @click="popupOpen(idx)">
      <template v-slot:icon>
        <profile-icon />
      </template>
    </icon-wrap>
  </li>
</template>

<script>
import IconWrap from "@/components/common/styled/icon/main/IconWrap.vue";
import ProfileIcon from "@/components/common/styled/icon/ProfileIcon.vue";
import { date } from "@/mixins/ListMixin";
export default {
  components: { IconWrap, ProfileIcon },
  props: {
    type: String,
    imgUrl: String,
    name: String,
    text: String,
    listDate: Date,
    idx: Number,
    profile: Boolean,
  },
  mixins: [date],
  methods: {
    popupOpen(idx) {
      this.$emit("open", idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  position: relative;
  display: flex;
  padding: 14px;
  margin-bottom: 14px;
  background: #ffffff;
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  align-items: center;
  .iconWrap {
    position: absolute;
    top: 50%;
    right: 18px;
    margin-top: -19px;
  }
  .img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 14px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &.bd {
      border: 2px solid $mainColor;
    }
  }
  &.myprofile {
    align-items: center;
    .img {
      width: 66px;
      height: 66px;
    }
    .noimg {
      width: 66px;
      height: 66px;
    }
    .profileWrap {
      .title {
        @include boxTxtStyle(
          $fz: 20px,
          $fw: 700,
          $ls: 1px,
          $lh: 27px,
          $c: #393a3e
        );
      }
      .text {
        @include boxTxtStyle($fz: 14px, $fw: 500, $lh: 19px, $c: #8e8f95);
      }
    }
  }
  .noimg {
    width: 54px;
    height: 54px;
    background-color: #ddd;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='38' viewBox='0 0 32 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.9894 14.8187C20.4008 16.3533 18.2462 17.2155 15.9996 17.2155C13.753 17.2155 11.5984 16.3533 10.0098 14.8187C8.42122 13.2841 7.52876 11.2028 7.52876 9.03254C7.52876 6.86229 8.42122 4.78093 10.0098 3.24633C11.5984 1.71174 13.753 0.849609 15.9996 0.849609C18.2462 0.849609 20.4008 1.71174 21.9894 3.24633C23.578 4.78093 24.4705 6.86229 24.4705 9.03254C24.4705 11.2028 23.578 13.2841 21.9894 14.8187ZM0.599609 33.5813C0.599609 26.8059 7.51182 21.3069 15.9996 21.3069C24.4874 21.3069 31.3996 26.8059 31.3996 33.5813C31.3996 33.6286 31.3956 33.6749 31.388 33.7198C31.3958 33.763 31.3997 33.8063 31.3997 33.8499C31.3997 35.6724 24.5049 37.1499 15.9997 37.1499C7.49455 37.1499 0.599731 35.6724 0.599731 33.8499C0.599731 33.8065 0.603628 33.7634 0.611349 33.7205C0.603624 33.6754 0.599609 33.6289 0.599609 33.5813Z' fill='%23199A8E'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 14px 10px;
  }
  .profileWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &.type2 {
      padding-right: 60px;
    }
    .title {
      margin-bottom: 3px;
      @include boxTxtStyle(
        $fz: 14px,
        $fw: 500,
        $ls: 1px,
        $lh: 19px,
        $c: #393a3e
      );
    }
    .text {
      display: block;
      @include boxTxtStyle($fz: 12px, $ls: 1px, $lh: 16px, $c: #8e8f95);
    }
    .date {
      @include boxTxtStyle($fz: 14px, $ls: 1px, $lh: 19px, $c: #393a3e);
    }
  }
  .txtWrap {
    .text {
      display: inline;
      margin-right: 13px;
    }
  }
}
</style>
