import Vuex from "vuex";
import mutations from "@/store/mutations";
import actions from "@/store/acitons";
import {
  getAuthFromLocalStorage,
  getUserFromLocalStorage,
} from "@/utils/localStorage";

const store = new Vuex.Store({
  state: {
    loading: null,
    popupStatus: null,
    userPool: [],
    authDetails: "",
    userData: "",
    cognitoUser: null,
    tokens: {
      accessToken: "",
      idToken: "",
      refreshToken: "",
    },
    docProfile: null,
    userInfo: null,
    reserve: null,
    payment: null,
    autoLogin: null,
    home: {
      requested: "",
      confirmed: "",
      myInfo: "",
      notice: "",
      myProfile: "",
    },
    username: getUserFromLocalStorage() || "",
    token: getAuthFromLocalStorage() || "",
    errcode: "",
    attributes: [],
    authenticated: false,
    docKeywords: null,
    docSelectArea: null,
    reLogin: false,
    error: null,
    lang: null,
  },
  mutations,
  actions,
});

export default store;
