import axios from "axios";
import { setInterceptors } from "./common/interceptors";

function creacteInstance(url) {
  return axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}${url}`,
  });
}

function creacteInstanceWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}${url}`,
  });
  return setInterceptors(instance);
}

function getPaymentWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_WEB_PAYMENT}${url}`,
  });
  return setInterceptors(instance);
}

function creacteBoardWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_BOARD_API_URL}${url}`,
  });
  return setInterceptors(instance);
}

function creacteCommonWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_COMMON}${url}`,
  });
  return setInterceptors(instance);
}

//로그아웃
export const logout = () => {
  return creacteInstanceWithAuth("logout").post();
};

export const getPayment = (query) => {
  return getPaymentWithAuth(`web${query}`).get();
};

export const instance = creacteInstance("member/");

export const member = creacteInstanceWithAuth("member/");

export const home = creacteInstanceWithAuth("home");

export const consult = creacteInstanceWithAuth("consult");

export const zoom = creacteCommonWithAuth("zoom/");

//게시판 목록
export const getBoardList = (req) => {
  return creacteBoardWithAuth("").post(
    "list_board.seize",
    req
  );
};

//게시판 상세보기
export const getBoardListDetail = (id) => {
  return creacteBoardWithAuth("").post(
    "load_board.seize",
    id
  );
};

export const getPaymentList = (query) => {
  return creacteInstanceWithAuth("payment/list").get(
    `${query}`
  );
};
