<template>
  <span class="icon">
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.94482 13.75H17.667M7.94482 18.75H14.0282M10.7226 5.99999H16.2782C19.0559 5.99999 19.0559 4.75 19.0559 3.5C19.0559 1 17.667 1 16.2782 1H10.7226C9.33371 1 7.94482 1 7.94482 3.5C7.94482 5.99999 9.33371 5.99999 10.7226 5.99999Z"
        class="stroke"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.0556 3.52441C23.6806 3.74941 26 5.28691 26 10.9994V18.4994C26 23.4994 24.6111 25.9994 17.6667 25.9994H9.33333C2.38889 25.9994 1 23.4994 1 18.4994V10.9994C1 5.29941 3.31944 3.74941 7.94444 3.52441"
        class="stroke"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.icon {
  .stroke {
    stroke: #b0b0b1;
  }
}

.active {
  .icon {
    .stroke {
      animation: fade 0.25s linear alternate;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fade {
  from {
    stroke: #b0b0b1;
  }
  to {
    stroke: $mainColor;
  }
}
</style>
