<template>
  <span class="icon">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path
        d="M14.15 14.9751C14.0504 14.9626 13.9496 14.9626 13.85 14.9751C12.7899 14.9394 11.7853 14.4931 11.0482 13.7304C10.3111 12.9676 9.89941 11.9483 9.90001 10.8876C9.90001 8.6251 11.725 6.7876 14 6.7876C15.0743 6.78574 16.1063 7.20612 16.8735 7.95811C17.6408 8.7101 18.0818 9.73347 18.1014 10.8076C18.1211 11.8817 17.7179 12.9205 16.9787 13.7001C16.2396 14.4797 15.2237 14.9376 14.15 14.9751ZM22.425 23.2251C20.1278 25.3364 17.1201 26.5056 14 26.5001C10.75 26.5001 7.80001 25.2626 5.57501 23.2251C5.70001 22.0501 6.45001 20.9001 7.78751 20.0001C11.2125 17.7251 16.8125 17.7251 20.2125 20.0001C21.55 20.9001 22.3 22.0501 22.425 23.2251Z"
        class="stroke"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 26.5C20.9037 26.5 26.5 20.9037 26.5 14C26.5 7.09625 20.9037 1.5 14 1.5C7.09625 1.5 1.5 7.09625 1.5 14C1.5 20.9037 7.09625 26.5 14 26.5Z"
        class="stroke"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.stroke {
  stroke: #7a7a7a;
}
</style>
