<template>
  <span class="icon cancelIcon">
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00586 34.3913L34.3913 7.00586M23.4394 39.8913L26.1894 37.1413M29.6086 33.7244L35.0857 28.2473"
        stroke="#FC6969"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.58363 48.4123H48.417M6.25258 21.4646L21.4693 6.24793C26.3276 1.3896 28.7568 1.36668 33.5693 6.17918L44.8213 17.4313C49.6338 22.2438 49.6109 24.6729 44.7526 29.5313L29.5359 44.7479C24.6776 49.6063 22.2484 49.6292 17.4359 44.8167L6.18383 33.5646C1.37134 28.7521 1.37133 26.3459 6.25258 21.4646Z"
        stroke="#FC6969"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
