<template>
  <div class="homeMyprofile">
    <h3 class="h">{{ $t("header.menu.list[3].depth[0].title") }}</h3>
    <div class="wrap">
      <div class="dateWrap" :class="$store.state.lang">
        <swiper
          :navigation="true"
          :modules="modules"
          :loop="true"
          class="mySwiper"
        >
          <swiper-slide
            v-for="item in btnList"
            :key="item"
            :data-history="item"
            :class="[
              item.className,
              ((data && !data[`${item.type}`]) || !data) && 'data',
            ]"
          >
            <div class="contentWrap">
              <h4 class="title">
                {{
                  item.type === "near_confirm_schedule" && className
                    ? liveTxt
                    : item.title
                }}
              </h4>
              <div :class="item.className">
                <strong v-if="data && data[`${item.type}`]" class="dateStyle">
                  <span class="date">
                    <i class="db">
                      <span v-if="data && data[`${item.type}`]">{{
                        $filters.formatDate(data[`${item.type}`].date, "type3")
                      }}</span>
                      <span class="day">{{
                        `(${day(data[`${item.type}`].date)})`
                      }}</span>
                    </i>
                    <i class="db">
                      <span v-if="data && data[`${item.type}`]" class="time">{{
                        ampmT(data[`${item.type}`].time)
                      }}</span>
                      <span class="ampm">
                        {{ ampm_(data[`${item.type}`].time) }}
                      </span>
                    </i>
                  </span>
                </strong>
                <button
                  class="btn"
                  v-if="data && data[`${item.type}`]"
                  :class="
                    item.type === 'near_confirm_schedule' &&
                    className &&
                    'liveBtn'
                  "
                  @click.prevent="
                    move(
                      data[`${item.type}`].booking_type,
                      item.className,
                      data[`${item.type}`].booking_sid,
                    )
                  "
                >
                  <span class="txt"
                    >{{
                      item.type === "near_confirm_schedule" && this.className
                        ? this.liveBtnTxt
                        : this.btnTxt
                    }}<live-btn-icon
                      v-if="item.type === 'near_confirm_schedule' && className"
                      class="liveBtnIcon"
                    />
                  </span>
                </button>
                <p class="msg" v-if="(data && !data[`${item.type}`]) || !data">
                  {{ msg }}
                </p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { date } from "@/mixins/ListMixin";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import LiveBtnIcon from "@/components/common/styled/icon/home/LiveBtnIcon.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    LiveBtnIcon,
  },
  props: {
    list: {
      type: Object,
      title: String,
      username: String,
      area: String,
      imgUrl: String,
    },
  },
  data() {
    return {
      liveTxt: this.$i18n.t("header.myinfo.liveTxt"),
      liveBtnTxt: "LIVE",
      btnTxt: this.$i18n.t("header.myinfo.btnTxt"),
      msg: this.$i18n.t("header.myinfo.nodata"),
      timestamp: null,
      compareTime: null,
      className: "",
      time: null,
      activeEnd: null,
      count: null,
      zoomDate: null,
      zoomTime: null,
      booking_sid: null,
      name: "",
      profile_url: "",
      affiliation: "",
      data: null,
      selectNum: 0,
      swiper: null,
      length: 0,
      btnList: [
        {
          type: "near_wait_schedule",
          className: "wait",
          title: this.$i18n.t("header.menu.list[0].depth[1].title"),
        },
        {
          type: "near_confirm_schedule",
          className: "confirm",
          title: this.$i18n.t("header.menu.list[0].depth[2].title"),
        },
        {
          type: "near_finish_schedule",
          className: "completed",
          title: this.$i18n.t("header.menu.list[0].depth[3].title"),
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  created() {
    this.getHomeMyInfo();
    this.dateFormat(new Date()) > this.compareTime
      ? (this.className = "active")
      : undefined;
  },
  mounted() {
    this.count = setInterval(this.getNow, 1000);
  },
  beforeUnmount() {
    clearInterval(this.count);
  },
  mixins: [date],
  methods: {
    popupOpen() {
      this.$emit("popupOpen");
    },
    async getHomeMyInfo() {
      try {
        const res = await this.$store.dispatch("HOME_MY_PROFILE");
        this.reserveTime(res);
        this.endTime(res.near_confirm_schedule?.view_end_time);
        this.name = res.name;
        this.data = res;
      } catch (error) {
        this.$store.state.loading = false;
        this.$store.state.error = error.response.data?.code;
      }
    },
    move(cType, type, id) {
      if (this.className && type === "confirm") {
        if (cType === "00") {
          this.$router.push(`/zoom/${this.booking_sid}`);
        } else if (cType === "01") {
          this.$router.push(`/metaverse/${this.booking_sid}`);
        } else if (cType === "02") {
          this.$router.push(`/voice/${this.booking_sid}`);
        }
      } else {
        this.$router.push(`/dx/${type}/list/1/${id}`);
      }
    },
    getNow() {
      const today = new Date();
      this.timestamp = today;
      today > this.compareTime && today <= this.activeEnd
        ? (this.className = "active")
        : (this.className = undefined);
    },
    dateFormat(date) {
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour >= 10 ? hour : "0" + hour;
      minute = minute >= 10 ? minute : "0" + minute;
      second = second >= 10 ? second : "0" + second;

      return (
        date.getFullYear() +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    reserveTime(date) {
      if (date.near_confirm_schedule) {
        this.zoomDate = date.near_confirm_schedule.date;
        this.zoomTime = date.near_confirm_schedule.time;
        this.booking_sid = date.near_confirm_schedule.booking_sid;
        this.time = new Date(
          this.zoomDate?.substr(0, 4),
          this.zoomDate?.substr(5, 2) - 1,
          this.zoomDate?.substr(8, 2),
          this.zoomTime?.substr(0, 2),
          this.zoomTime?.substr(3, 2),
        );
        var tDate = new Date(
          this.zoomDate?.substr(0, 4),
          this.zoomDate?.substr(5, 2) - 1,
          this.zoomDate?.substr(8, 2),
          this.zoomTime?.substr(0, 2),
          this.zoomTime?.substr(3, 2) - 10,
        );
        this.compareTime = tDate;
      }
    },
    endTime(time) {
      var tDate = new Date(
        this.zoomDate?.substr(0, 4),
        this.zoomDate?.substr(5, 2) - 1,
        this.zoomDate?.substr(8, 2),
        time?.substr(0, 2),
        time?.substr(3, 2),
      );
      tDate.setMinutes(tDate.getMinutes() + 1);
      this.activeEnd = tDate;
    },
  },
};
</script>

<style lang="scss">
.homeMyprofile {
  .swiper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
  }
  .contentWrap {
    width: 100%;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(
      --swiper-wrapper-transition-timing-function,
      initial
    );
    box-sizing: content-box;
  }
  .swiper-slide {
    padding: 27px 50px 28px;
    // min-height: 234px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    &.data {
      padding: 0;
      > div {
        padding: 35px 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        min-height: 175px;
      }
    }
  }
  .timeNum {
    margin-right: 2px;
    font-family: $interFont;
    font-weight: 600;
  }
  .btn {
    width: auto;
    margin: 19px auto 0;
    display: block;
    padding: 7px 21px;
    min-width: 174px;
    text-align: center;
    background-color: $mainColor;
    border-radius: 20px;
    @include boxTxtStyle($fz: 14px, $lh: 23px, $fw: 700, $c: #fff);
  }
  .imgTitleWrap {
    position: absolute;
    top: -76px;
    left: 50%;
    margin-left: -60px;
  }
  .imgHeight {
    width: 120px;
    height: 120px;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-prev {
    display: block;
  }
  .swiper-button-next,
  .swiper-button-prev {
    margin-top: -17px;
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 -1.5299e-06C7.83475 -2.37486e-06 2.37486e-06 7.83475 1.5299e-06 17.5C6.84936e-07 27.1652 7.83475 35 17.5 35C27.1652 35 35 27.1652 35 17.5C35 7.83475 27.1653 -6.84936e-07 17.5 -1.5299e-06Z' fill='%23064D45'/%3E%3Cpath d='M19.7052 10.0097C20.0377 10.0097 20.3702 10.1322 20.6327 10.3947C20.8768 10.6417 21.0137 10.975 21.0137 11.3222C21.0137 11.6695 20.8768 12.0027 20.6327 12.2497L15.3827 17.4997L20.6327 22.7497C20.8768 22.9967 21.0137 23.33 21.0137 23.6772C21.0137 24.0245 20.8768 24.3577 20.6327 24.6047C20.1252 25.1122 19.2852 25.1122 18.7777 24.6047L12.6002 18.4272C12.0927 17.9197 12.0927 17.0797 12.6002 16.5722L18.7777 10.3947C19.0402 10.1322 19.3727 10.0097 19.7052 10.0097Z' fill='white'/%3E%3C/svg%3E");
    opacity: 1;
  }
  .swiper-button-next {
    background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 35C27.1652 35 35 27.1652 35 17.5C35 7.83475 27.1652 0 17.5 0C7.83475 0 0 7.83475 0 17.5C0 27.1652 7.83475 35 17.5 35Z' fill='%23064D45'/%3E%3Cpath d='M15.2948 24.9903C14.9623 24.9903 14.6298 24.8678 14.3673 24.6053C14.1232 24.3583 13.9863 24.025 13.9863 23.6778C13.9863 23.3305 14.1232 22.9973 14.3673 22.7503L19.6173 17.5003L14.3673 12.2503C14.1232 12.0033 13.9863 11.67 13.9863 11.3228C13.9863 10.9755 14.1232 10.6423 14.3673 10.3953C14.8748 9.88777 15.7148 9.88777 16.2223 10.3953L22.3998 16.5728C22.9073 17.0803 22.9073 17.9203 22.3998 18.4278L16.2223 24.6053C15.9598 24.8678 15.6273 24.9903 15.2948 24.9903Z' fill='white'/%3E%3C/svg%3E");
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
  .wrap {
    position: relative;
    margin: 0 14px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid $mainColor;
    min-height: 175px;
  }

  .txt {
    display: flex;
    justify-content: center;
  }
  .liveBtn {
    background-color: #fc6969;
    .txt {
      display: flex;
      justify-content: center;
      @include boxTxtStyle($fz: 16px, $lh: 21px, $fw: 900, $c: #fff);
      span {
        display: flex;
        margin-left: 10px;
      }
    }
  }

  .imgWrap {
    /* position: absolute;
    top: -76px;
    left: 50%;
    margin-left: -60px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid $mainColor;
    overflow: hidden;
    background: #e9eaf2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }

  .titleWrap {
    margin-bottom: 11px;

    .title {
      display: flex;
      justify-content: center;

      .dr {
        @include boxTxtStyle($fz: 16px, $lh: 23px, $fw: 700, $c: #393a3e);
      }

      .name {
        @include boxTxtStyle($fz: 16px, $lh: 23px, $fw: 500, $c: #393a3e);
      }
    }

    .area {
      @include boxTxtStyle($fz: 14px, $lh: 19px, $fw: 500, $c: #8e8f95);
    }
  }

  .dateWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 175px;
    span {
      display: inline;
    }
    .title {
      @include boxTxtStyle($fw: 500, $fz: 19px, $lh: 26px, $c: $mainColor);
      margin-bottom: 5px;
    }
    .bookingList {
      position: relative;
      padding-bottom: 36px;
      .btn {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 23px;
          width: auto;
          min-height: 36px;
          background: #f8f9fc;
          border: 1px solid #dadada;
          border-radius: 20px;
          @include boxTxtStyle($fz: 14px, $fw: 700, $lh: 19px, $c: #7a7a7a);
        }
      }
      .active {
        .btn {
          button {
            background-color: $mainColor;
            border-color: $mainColor;
            color: #fff;
          }
        }
      }
      @for $i from 1 through 3 {
        > li:nth-child(#{$i}) {
          @if $i == 1 {
            .btn {
              left: 0;
            }
          } @else {
            .btn {
              left: #{calc(100% / 3) * ($i - 1)};
            }
          }
        }
      }
    }
    .confirm {
      .title {
        color: #fc6969;
      }
    }

    .completed {
      .title {
        color: #393a3e;
      }
    }
    &.zoomActive {
      background: #199a8e;
      border: 1px solid #dadada;
      border-radius: 24px;

      .date,
      .ampm {
        color: #fff;
      }
    }

    .dateStyle {
      display: flex;
      justify-content: center;
      .day {
        margin-left: 2px;
        margin-right: 10px;
        @include boxTxtStyle($fz: 16px, $lh: 32px, $fw: 500, $c: #50555c);
      }
      .ampm {
        @include boxTxtStyle($fz: 14px, $lh: 32px, $fw: 500, $c: #50555c);
      }
      .date {
        display: flex;
        align-items: flex-end;
        @include boxTxtStyle(
          $ff: $interFont,
          $fz: 26px,
          $lh: 35px,
          $fw: 700,
          $c: #353535
        );
      }
      .time {
        position: relative;
        padding-left: 10px;
        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -5px;
          content: "";
          display: block;
          width: 1px;
          height: 11px;
          background-color: #827f7f;
        }
      }
    }

    .wait {
      .dateStyle {
        .date {
          .time {
            color: $mainColor;
          }
        }
      }
    }
    .confirm {
      .dateStyle {
        .date {
          .time {
            color: #fc6969;
          }
        }
      }
    }

    .ampm {
      margin-left: 2px;
    }

    .msg {
      margin-top: 5px;
      @include boxTxtStyle($fz: 16px, $lh: 19px, $fw: 400, $c: #393a3e);
    }

    .btnWrap {
      display: flex;
      margin-right: 19px;
      margin-bottom: 0;
      line-height: 17px;

      svg {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

$mobile: 390px;
$ru_mobile: 420px;

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin ru_mobile {
  @media (max-width: $ru_mobile) {
    @content;
  }
}

@include mobile {
  .homeMyprofile {
    .db {
      display: block;
    }
    .dateWrap {
      .dateStyle {
        .day {
          margin-right: 0;
        }
        .date {
          flex-direction: column;
          justify-content: center;
        }
        .time {
          padding-left: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

@include ru_mobile {
  .homeMyprofile {
    .ru {
      .db {
        display: block;
      }
      .dateWrap {
        .dateStyle {
          .day {
            margin-right: 0;
          }
          .date {
            flex-direction: column;
            justify-content: center;
          }
          .time {
            padding-left: 0;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
