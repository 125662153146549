<template>
  <span class="icon">
    <svg
      width="58"
      height="55"
      viewBox="0 0 58 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 19.9004H55.973"
        stroke="#FC6969"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.7991 48.863H14.009C4.42879 48.863 2 46.4357 2 36.7542V14.0812C2 5.30993 3.997 2.49649 11.5262 2.05517C12.2819 2.02758 13.1184 2 14.009 2H43.991C53.5712 2 56 4.42728 56 14.1088V26.2728"
        stroke="#FC6969"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.5 46L41.5 38M41.5 46L49.5 38M12.7949 36.4502H23.5895M45.1787 52.9998C48.0416 52.9998 50.7873 51.8374 52.8117 49.7682C54.836 47.6991 55.9733 44.8928 55.9733 41.9667C55.9733 39.0405 54.836 36.2342 52.8117 34.1651C50.7873 32.096 48.0416 30.9336 45.1787 30.9336C42.3158 30.9336 39.5702 32.096 37.5458 34.1651C35.5214 36.2342 34.3841 39.0405 34.3841 41.9667C34.3841 44.8928 35.5214 47.6991 37.5458 49.7682C39.5702 51.8374 42.3158 52.9998 45.1787 52.9998Z"
        stroke="#FC6969"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
