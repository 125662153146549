import { consult } from ".";

// 상담목록 조회
function getConsultList(query) {
  return consult.get(`/list${query}`);
}

// 상담 상세 조회
function getConsultDetail(id) {
  return consult.get(`/${id}`);
}

// 상담 취소
function consultCancel(id) {
  return consult.put(`/${id}/cancle`);
}

// 상담 종료시간
function consultEnd(id) {
  return consult.post(`/${id}/end-date`);
}

// 상담 매니저 등록/수정
function setConsultManager(reqData) {
  return consult.post(`/${reqData.id}/consult-manager`, reqData.data);
}

//반려사유 조회
function getReject(id) {
  return consult.get(`/reject/${id}`);
}

export {
  getConsultList,
  getConsultDetail,
  consultCancel,
  setConsultManager,
  getReject,
  consultEnd,
};
