<template>
  <div>
    <router-view v-if="isOnLine" />
    <ErrorView v-if="!isOnLine" />
    <loading-spinner v-if="$store.state.loading" />
    <div class="popupWrap" v-if="$store.state.reLogin || reLogin">
      <popup
        :type="failPopup.type"
        :title="failPopup.title"
        :text="failPopup.text"
        :btnTxt="failPopup.BtnTxt"
        :link="failPopup.link"
        :fail="failPopup.fail"
        @close="reLoginEvent"
        :vhtml="failPopup.vhtml"
      />
    </div>
    <div class="address" :class="!addressClass && 'addressN'">
      <ul>
        <li v-for="list in address" :key="list">
          <a v-if="list.link" :href="list.link" target="_blank">{{
            list.title
          }}</a>
          <template v-else>
            {{ list.title }}
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/style.css";
import LoadingSpinner from "./components/common/styled/loading/LoadingSpinner.vue";
import Popup from "./components/common/styled/popup/Popup.vue";
import {
  deleteAllLocalStorage,
  getLangLocalStorage,
  saveLangLocalStorage,
} from "./utils/localStorage";
import ErrorView from "./views/ErrorView.vue";
export default {
  components: { LoadingSpinner, Popup, ErrorView },
  setup() {
    var meetingFinish = null;
    var payFinish = null;
    return {
      meetingFinish,
      payFinish,
    };
  },
  data() {
    return {
      isOnLine: navigator.onLine,
      failPopup: {
        type: "type1",
        title: "다시 로그인",
        text: `회원정보를 찾을 수 없습니다. <br>다시 로그인 해주세요.`,
        BtnTxt: "로그인",
        link: "/login",
        open: false,
        fail: true,
        vhtml: true,
      },
      address: [
        { title: "헬스온클라우드" },
        { title: "사업자등록번호: 880-81-02121" },
        { title: "대표자: 박억숭" },
        { title: "연락처: +82) 02 566 0966" },
        { title: "서울특별시 강남구 봉은사로 16길 16" },
        { title: "통신판매신고번호: 제 2023-서울강남-03099호" },
        { title: "개인 정보 보호 정책", link: "/join/policy_ko.html" },
        { title: "서비스 약관", link: "/join/service_ko.html" },
      ],
    };
  },
  created() {
    let locale =
      getLangLocalStorage() || navigator.language || navigator.userLanguage;
    locale = locale.substring(0, 2);
    saveLangLocalStorage(locale);
    switch (locale) {
      case "ko":
        locale = "ko";
        break;
      case "ru":
        locale = "ru";
        break;
      default:
        locale = "en";
        break;
    }
    document.querySelector("html").lang = locale;
    this.$i18n.locale = locale;
    this.$store.state.lang = locale;
  },
  computed: {
    reLogin() {
      return this.$store.state.error === -968;
    },
    addressClass() {
      return this.$route.name == "home" ? true : false;
    },
  },
  mounted() {
    document.addEventListener("touchmove", function (event) {
      event.preventDefault();
    });
    var scrollingDiv = document.getElementById("app");
    scrollingDiv.addEventListener("touchmove", function (event) {
      event.stopPropagation();
    });

    this.connectScript();
    this.$store.state.loading = false;
    window.addEventListener("online", () => {
      this.isOnLine = true;
    });
    window.addEventListener("offline", () => {
      this.isOnLine = false;
    });
    if (this.reLogin) {
      document.querySelector("html").style.overflowY = "hidden";
    }
    if (this.$store.state.token) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.webkit?.messageHandlers.hideSplash.postMessage("iphone");
        this.isLoading = false;
        this.onBoarding = true;
      }
      if (window.Android) {
        window.Android.hideSplash();
        this.isLoading = false;
        this.onBoarding = true;
      } else {
        this.isLoading = false;
        this.onBoarding = true;
      }
    }
  },
  methods: {
    reLoginEvent() {
      deleteAllLocalStorage();
      this.$router.push("/login");
      this.$store.state.loading = false;
      this.$store.state.token = null;
      this.$store.state.reLogin = false;
      this.$store.state.error = null;
      console.clear;
    },
    connectScript() {
      let scriptElement = document.createElement("script");
      scriptElement.setAttribute("src", "./connectIn.js");
      document.head.appendChild(scriptElement);
    },
  },
};
</script>

<style lang="scss">
body {
  overflow-x: hidden;
  overflow-y: auto;
}
.popupWrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 200;
}
#app,
aside ul,
.squareMultipleWrap > div {
  max-width: 1024px;
  margin: 0 auto;
}
#app {
  /* overflow: hidden; */
  overflow-y: auto;
  background-color: #fff;
}
html {
  background-color: #f6f5f5;
}
#app {
  width: 100%;
  height: 100vh;
  /* overflow-y: auto; */
  -webkit-overflow-scrolling: touch;
}
.address {
  padding: 10px 22px 84px;
  @include boxTxtStyle($fz: 12px, $lh: 20px, $c: #565656);
  background: #f4f4f4;
  a {
    @include boxTxtStyle($fz: 12px, $lh: 20px, $c: #565656);
  }
  &.addressN {
    display: none;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
      &:first-child {
        padding-left: 0;
        margin-left: 0;
        &::after {
          display: none;
        }
      }
      &::after {
        @include pseudo($l: 0, $t: 50%);
        width: 1px;
        height: 14px;
        margin-top: -7px;
        background-color: #ccc;
      }
    }
  }
}

$mobile: 1024px;

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}
@include mobile {
  html {
    background-color: #fff;
  }
}
</style>
