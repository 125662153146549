<template>
  <div class="mainHeader">
    <div class="mainHeaderExplan">
      <h2 class="mainHeaderTitle">{{ title }}</h2>
      <p class="mainHeaderTxt">{{ txt }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    txt: String,
    placeHolder: String,
  },
  data() {
    return {
      myprofile: {
        title: "My profile",
        username: null,
        area: null,
        btnTxt: "Live",
        imgUrl: null,
        date: null,
        time: null,
        state: null,
        btnList: [
          {
            type: "near_wait_schedule",
            className: "wait",
            title: this.$i18n.t("header.menu.list[0].depth[1].title"),
          },
          {
            type: "near_confirm_schedule",
            className: "confirm",
            title: this.$i18n.t("header.menu.list[0].depth[2].title"),
          },
          {
            type: "near_finish_schedule",
            className: "completed",
            title: this.$i18n.t("header.menu.list[0].depth[3].title"),
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.mainHeader {
  padding: 33px 14px 28px;
  .mainHeaderExplan {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .mainHeaderTitle {
      @include boxTxtStyle($fw: 900, $fz: 20px, $lh: 27px, $c: #fff);
    }
    .mainHeaderTxt {
      text-align: left;
      @include boxTxtStyle($fz: 14px, $lh: 19px, $ls: 1px, $c: #fff);
    }
  }
}
</style>
