<template>
  <span class="icon">
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6497 14.4746C13.5501 14.4621 13.4493 14.4621 13.3497 14.4746C12.2896 14.4389 11.285 13.9926 10.5479 13.2299C9.81083 12.4671 9.39911 11.4478 9.39971 10.3871C9.39971 8.12462 11.2247 6.28712 13.4997 6.28712C14.574 6.28525 15.606 6.70563 16.3732 7.45762C17.1405 8.20961 17.5814 9.23298 17.6011 10.3071C17.6208 11.3812 17.2176 12.4201 16.4784 13.1996C15.7393 13.9792 14.7234 14.4371 13.6497 14.4746ZM21.9247 22.7246C19.6275 24.836 16.6198 26.0052 13.4997 25.9996C10.2497 25.9996 7.29971 24.7621 5.07471 22.7246C5.19971 21.5496 5.94971 20.3996 7.28721 19.4996C10.7122 17.2246 16.3122 17.2246 19.7122 19.4996C21.0497 20.3996 21.7997 21.5496 21.9247 22.7246Z"
        class="stroke"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 26C20.4037 26 26 20.4037 26 13.5C26 6.59625 20.4037 1 13.5 1C6.59625 1 1 6.59625 1 13.5C1 20.4037 6.59625 26 13.5 26Z"
        class="stroke"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.icon {
  .stroke {
    stroke: #b0b0b1;
  }
}

.active {
  .icon {
    .stroke {
      animation: fade 0.25s linear alternate;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fade {
  from {
    stroke: #b0b0b1;
  }
  to {
    stroke: $mainColor;
  }
}
</style>
