<template>
  <li class="list">
    <router-link :to="item.url">{{ item.title }}</router-link>
  </li>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
.twoDepthWrap {
  .list {
    a {
      position: relative;
      display: block;
      @include boxTxtStyle(
        $fz: 16px,
        $ls: -0.9px,
        $fw: 500,
        $lh: 41px,
        $c: #393a3e
      );
      &:hover,
      &:active {
        color: $mainColor;
        &::before {
          opacity: 1;
        }
      }
      &::before {
        @include pseudo($r: 0, $t: 50%);
        opacity: 0;
        width: 17px;
        height: 12px;
        margin-top: -6px;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='14' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7.375L6.45455 13L18 1' stroke='%23199A8E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
  }
}
</style>
