<template>
  <div class="icon checkIcon">
    <svg :width="width" :height="height" viewBox="0 0 14 10" fill="none">
      <path
        d="M1.66675 5L5.66675 9L12.3334 1"
        class="stroke"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  },
};
</script>

<style lang="scss" scoped>
.checkIcon {
  display: flex;
  .stroke {
    stroke: #13b97d;
  }
  &.fail {
    .stroke {
      stroke: #fc6969;
    }
  }
}
</style>
