// import { logout } from "@/api";
import {
  getBoardList,
  getBoardListDetail,
  getPayment,
  getPaymentList,
} from "@/api";
import {
  emailComfirm,
  getMyPage,
  sendCode,
  setMyNewPw,
  setMyPage,
  setMyPw,
} from "@/api/auth";
import {
  consultCancel,
  consultEnd,
  getConsultDetail,
  getConsultList,
  getReject,
  setConsultManager,
} from "@/api/consult";
import {
  getHomeMyInfo,
  getHomeZoom,
  getHomeDoctor,
  getHomeDoctorSked,
  getHomeDoctorSkedDetail,
  postHomeBooking,
  postHomeBookingPayment,
  getHomeDoctorProfile,
  getHomeMyProfile,
  getHomeDoctorSearch,
  getHomeMetaverse,
} from "@/api/home";
import router from "@/router/router";
import {
  deleteAllLocalStorage,
  saveAuthRefreshTokenLocalStorage,
  saveAuthToLocalStorage,
  saveUserToLocalStorage,
} from "@/utils/localStorage";

export default {
  /* home */
  //zoom
  // eslint-disable-next-line
  async GET_ZOOM({ commit }, booking_sid) {
    const { data } = await getHomeZoom(booking_sid);
    return data;
  },

  //metaverse
  async GET_METAVERSE(state, booking_sid) {
    const { data } = await getHomeMetaverse(booking_sid);
    return data;
  },

  //정보
  async HOME_MY_INFO({ commit }) {
    const { data } = await getHomeMyInfo();
    commit("getHomeMyInfo", data);
    return data;
  },

  //my profile
  async HOME_MY_PROFILE({ commit }) {
    const { data } = await getHomeMyProfile();
    commit("getHomeMyProfile", data);
    return data;
  },

  //내정보 조회
  // eslint-disable-next-line
  async GET_MY_PROFILE(state) {
    const { data } = await getMyPage();
    return data;
  },
  //내정보 수정
  async SET_MY_PROFILE(state, userData) {
    const { data } = await setMyPage(userData);
    return data;
  },

  //내정보 비밀번호 수정
  async PW_MODIFY(state, pw) {
    const { data } = await setMyPw(pw);
    return data;
  },

  //의료진 조회
  // eslint-disable-next-line
  async GET_DOCTOR_LIST({ commit }, query) {
    const { data } = await getHomeDoctor(query);
    return data;
  },

  //의료진 프로필 조회
  // eslint-disable-next-line
  async GET_DOCTOR_LIST_PROFILE({ commit }, id) {
    const { data } = await getHomeDoctorProfile(id);
    return data;
  },

  //의료진 검색
  async GET_DOCTOR_SEARCH(state, obj) {
    const { data } = await getHomeDoctorSearch(obj);

    return data;
  },

  //스케줄 조회
  // eslint-disable-next-line
  async GET_DOCTOR_LIST_SKED({ commit }, obj) {
    const { data } = await getHomeDoctorSked(obj);
    return data;
  },

  //날짜 별
  // eslint-disable-next-line
  async GET_DOCTOR_LIST_SKED_DETAIL({ commit }, obj) {
    const { data } = await getHomeDoctorSkedDetail(obj);
    return data;
  },

  // eslint-disable-next-line
  async POST_BOOKING({ commit }, obj) {
    const { data } = await postHomeBooking(obj);
    return data;
  },

  // eslint-disable-next-line
  async POST_BOOKING_PAYMENT({ commit }, query) {
    const { data } = await postHomeBookingPayment(query);
    return data;
  },

  //web payment
  async GET_PAYMENT(state, query) {
    const { data } = await getPayment(query);
    return data;
  },

  /*상담관리*/
  //상담 목록
  async GET_CONSULT_LIST(state, query) {
    const { data } = await getConsultList(query);
    return data;
  },

  //상담 상세 조회
  async GET_CONSULT_DETAIL(state, query) {
    const { data } = await getConsultDetail(query);
    return data;
  },

  //상담 취소
  async SET_CONSULT_CANCEL(state, id) {
    const { data } = await consultCancel(id);
    return data;
  },

  //상담 종료 시간
  async CONSULT_END(state, id) {
    const { data } = await consultEnd(id);
    return data;
  },

  //매니저 상담 등록/수정
  async SET_CONSULT_MANAGER(state, reqData) {
    const { data } = await setConsultManager(reqData);
    return data;
  },

  //로그아웃
  async LOGOUT({ commit }) {
    commit("setToken", null);
    commit("setUsername", null);
    deleteAllLocalStorage();
  },

  // eslint-disable-next-line
  async EMAIL_COMFIRM({ commit }, query) {
    const { data } = await emailComfirm(query);
    return data;
  },

  //Home 공지사항
  //고객센터 - 공지사항, FAQ
  async BOARD_LIST(state, req) {
    const { data } = await getBoardList(req);

    return data;
  },

  //공지사항, FAQ 상세보기
  async BOARD_LIST_DETAIL(state, id) {
    const { data } = await getBoardListDetail(id);
    return data;
  },

  //결제내역
  async GET_PAYMENT_LIST(state, query) {
    const { data } = await getPaymentList(query);
    return data;
  },

  //반려사유 조회
  async GET_REJECT(state, id) {
    const { data } = await getReject(id);
    return data;
  },

  //이메일에 코드 보내기
  async EMAIL_SEND_CODE(state, email) {
    const { data } = await sendCode(email);
    return data;
  },

  //로그인 - 비밀번호 재설정
  async EMAIL_CHACK_CODE(state, email) {
    const { data } = await setMyNewPw(email);
    return data;
  },

  //로그인
  /* eslint-disable */
  signIn({ state, commit, dispatch }, authData) {
    commit("clearError");
    commit("setUserPool");
    commit("setCognitoDetails", authData);
    state.cognitoUser.authenticateUser(state.authDetails, {
      onSuccess: async (result) => {
        state.token = result.accessToken.jwtToken;
        saveAuthToLocalStorage(result.accessToken.jwtToken);
        saveUserToLocalStorage(authData.Username);
        if (state.autoLogin) {
          saveAuthRefreshTokenLocalStorage(result.refreshToken.token);
        }
        commit("setTokens", result);
        commit("signIn");
        dispatch("getUserAttributes");
        return;
      },
      onFailure: (err) => {
        commit("setError", JSON.stringify(err.code));
        state.loading = false;
        state.popupStatus = true;
      },
    });
  },
  tryAutoSignIn({ state, commit, dispatch }) {
    commit("setUserPool");
    let cognitoUser = state.userPool.getCurrentUser();
    if (cognitoUser != null) {
      commit("setCognitoUser", cognitoUser);
      state.cognitoUser.getSession(function (err, session) {
        if (err) {
          console.error(JSON.stringify(err));
        } else {
          commit("setTokens", session);
          commit("signIn");
          dispatch("getUserAttributes");
          dispatch("setLogoutTimer", 3600);
        }
      });
    }
  },
  getUserAttributes({ commit, dispatch }) {
    state.cognitoUser.getUserAttributes(function (err, attributes) {
      if (err) {
        console.error(JSON.stringify(err));
      } else {
        commit("setAttributes", attributes);
      }
    });
  },
  SIGNOUT({ state, commit, dispatch }, expirationTime) {
    setTimeout(() => {
      dispatch("signOut");
    }, expirationTime * 1000);
  },

  SIGNOUT({ commit }) {
    commit("signOut");
    commit("setToken", null);
    commit("setUsername", null);
    deleteAllLocalStorage();
    router.push("/login");
  },
};
