<template>
  <span class="icon">
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none">
      <path
        d="M12.7998 23.492C22.6008 15.905 35.3988 15.905 45.1998 23.492M17.1198 31.835C24.3288 26.273 33.6978 26.273 40.9068 31.835M23.0598 40.178C26.6508 37.397 31.3488 37.397 34.9398 40.178"
        stroke="#199A8E"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.9 56H37.1C50.6 56 56 50.6 56 37.1V20.9C56 7.4 50.6 2 37.1 2H20.9C7.4 2 2 7.4 2 20.9V37.1C2 50.6 7.4 56 20.9 56Z"
        stroke="#199A8E"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
