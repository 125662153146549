const token = "healthOn-patient-token";
const user = "healthOn-patient-user";
const refreshToken = "healthOn-patient-refreshToken";
const lang = "healthOn-patient-lang";

function saveAuthRefreshTokenLocalStorage(value) {
  localStorage.setItem(refreshToken, value);
}

function saveAuthToLocalStorage(value) {
  localStorage.setItem(token, value);
}

function saveUserToLocalStorage(value) {
  localStorage.setItem(user, value);
}

function saveLangLocalStorage(value) {
  localStorage.setItem(lang, value);
}

function getAuthFromLocalStorage() {
  return localStorage.getItem(token);
}

function getAuthRefreshTokenLocalStorage() {
  return localStorage.getItem(refreshToken);
}

function getUserFromLocalStorage() {
  return localStorage.getItem(user);
}

function getLangLocalStorage() {
  return localStorage.getItem(lang);
}

function deleteLocalStorage(value) {
  localStorage.removeItem(value);
}

function deleteAllLocalStorage() {
  localStorage.removeItem(user);
  localStorage.removeItem(token);
}
export {
  saveAuthToLocalStorage,
  saveUserToLocalStorage,
  getAuthFromLocalStorage,
  getUserFromLocalStorage,
  deleteLocalStorage,
  deleteAllLocalStorage,
  saveAuthRefreshTokenLocalStorage,
  getAuthRefreshTokenLocalStorage,
  getLangLocalStorage,
  saveLangLocalStorage,
};
