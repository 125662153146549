<template>
  <nav class="mainMenu">
    <div class="menuWrap">
      <h2 class="h">{{ title }}</h2>

      <!-- my profile -->
      <profile-list
        profile
        :name="myprofile.patient_name"
        :imgUrl="myprofile.profile_url"
      />

      <!-- menu list -->
      <ul class="menuList">
        <main-menu-item
          v-for="item in list"
          :key="item.title"
          :item="item"
          :class="{ active: item.id === activeId }"
          @menu="selectMember"
        />
      </ul>
      <close-btn @close="menuClose" />
    </div>
    <i class="bg"></i>
  </nav>
</template>

<script>
import CloseBtn from "../styled/btn/CloseBtn.vue";
import ProfileList from "../styled/list/ProfileList.vue";
import MainMenuItem from "./MainMenuItem.vue";
import i18n from "@/i18n/index";

const { t } = i18n.global;

//상담 관리
const menu1 = "/dx/";

//결제 내역
const menu2 = "/payment/";

//고객센터
const menu3 = "/cs/";

//내정보 관리
const menu4 = "/myprofile/";

export default {
  components: { ProfileList, MainMenuItem, CloseBtn },
  data() {
    return {
      myprofile: this.$store.state.home.myProfile,
      activeId: 1,
      title: t("header.menu.title"),
      list: [
        {
          id: 1,
          title: t("header.menu.list[0].title"),
          type: "dx",
          depth: [
            {
              id: 1,
              title: t("header.menu.list[0].depth[1].title"),
              url: `${menu1}wait/list`,
            },
            {
              id: 2,
              title: t("header.menu.list[0].depth[2].title"),
              url: `${menu1}confirm/list`,
            },
            {
              id: 3,
              title: t("header.menu.list[0].depth[3].title"),
              url: `${menu1}completed/list`,
            },
            {
              id: 4,
              title: t("header.menu.list[0].depth[4].title"),
              url: `${menu1}reject/list`,
            },
          ],
        },
        {
          id: 2,
          type: "payment",
          title: t("header.menu.list[1].title"),
          depth: [
            {
              id: 1,
              title: t("header.menu.list[1].depth[0].title"),
              url: `${menu2}`,
            },
          ],
        },
        {
          id: 3,
          title: t("header.menu.list[2].title"),
          type: "service",
          depth: [
            {
              id: 1,
              title: t("header.menu.list[2].depth[0].title"),
              url: `${menu3}notice`,
            },
            {
              id: 2,
              title: t("header.menu.list[2].depth[1].title"),
              url: `${menu3}faq`,
            },
          ],
        },
        {
          id: 4,
          title: t("header.menu.list[3].title"),
          type: "myprofile",
          depth: [
            {
              id: 1,
              title: t("header.menu.list[3].depth[0].title"),
              url: "/mypage",
            },
            {
              id: 3,
              title: t("header.menu.list[3].depth[1].title"),
              url: `${menu4}pwchange`,
            },
          ],
        },
        {
          id: 5,
          title: t("header.menu.list[4].title"),
          type: "logout",
        },
      ],
    };
  },

  mounted() {
    document.querySelector("html").style.overflowY = "hidden";
    document.body.style.overflowY = "hidden";
  },
  beforeUnmount() {
    document.querySelector("html").style.overflowY = "auto";
    document.body.style.overflowY = "auto";
  },
  methods: {
    menuClose() {
      this.$emit("close");
    },
    selectMember(id) {
      this.activeId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.mainMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 600px;
  min-height: 100vh;
  padding-top: 30px;
  padding-right: 30px;
  z-index: 10;
  text-align: left;
  > div {
    position: relative;
    height: 100%;
    min-height: calc(100vh - 30px);
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 0px 30px 30px 0px;
  }
  .menuList {
    position: relative;
    padding-top: 19px;
    min-height: calc(100vh - 163px);
    background-color: #e8ebf1;
    &::before {
      @include pseudo($r: 0, $t: 0);
      width: 50%;
      min-height: calc(100vh - 163px);
      background-color: #fff;
    }
  }
  .profile {
    min-height: 133px;
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid #cbcdd6;
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #101623, $alpha: 0.3);
    z-index: -1;
  }
}
</style>
