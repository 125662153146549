<template>
  <aside class="quickmenu">
    <ul>
      <li
        v-for="({ title, link }, idx) in list"
        :key="title"
        :class="
          (this.$route.fullPath.includes(title) ||
            (idx === 4 && this.$route.fullPath.includes('mypage'))) &&
          'active'
        "
      >
        <router-link :to="link">
          <home-icon v-if="title === 'home'" />
          <consult-icon v-if="title === 'dx'" />
          <payment-icon v-if="title === 'payment'" />
          <cs-icon v-if="title === 'cs'" />
          <profile-icon v-if="title === 'myprofile'" />
          <span class="h">{{ title }}</span>
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
import HomeIcon from "@/components/common/styled/icon/quickmenu/HomeIcon.vue";
import ProfileIcon from "@/components/common/styled/icon/quickmenu/ProfileIcon.vue";
import CsIcon from "@/components/common/styled/icon/quickmenu/CsIcon.vue";
import ConsultIcon from "@/components/common/styled/icon/quickmenu/ConsultIcon.vue";
import PaymentIcon from "@/components/common/styled/icon/quickmenu/PaymentIcon.vue";

export default {
  components: {
    HomeIcon,
    ProfileIcon,
    CsIcon,
    ConsultIcon,
    PaymentIcon,
  },
  props: {
    list: Array,
  },
  data() {
    return {
      active: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.quickmenu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  ul {
    padding: 0 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 74px;
    border-top: 2px solid #e9e9eb;
    background: #ffffff;
    box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.12);
  }
  .icon {
    vertical-align: top;
    display: inline-flex;
  }
  li {
    height: 74px;
    display: flex;
    align-items: center;
  }
  .active {
    position: relative;
  }
}
</style>
