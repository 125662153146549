<template>
  <button>
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.17048 13.5317C4.30286 13.5317 1 14.1165 1 16.4584C1 18.8003 4.2819 19.406 8.17048 19.406C12.0381 19.406 15.34 18.8203 15.34 16.4793C15.34 14.1384 12.059 13.5317 8.17048 13.5317Z"
        fill="none"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.17044 10.1914C10.7085 10.1914 12.7657 8.13333 12.7657 5.59524C12.7657 3.05714 10.7085 1 8.17044 1C5.63234 1 3.57425 3.05714 3.57425 5.59524C3.56567 8.12476 5.60948 10.1829 8.13806 10.1914H8.17044Z"
        fill="none"
        stroke="white"
        stroke-width="1.42857"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {};
</script>

<style></style>
