<template>
  <span>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="white" />
      <path
        d="M16.3711 12.1643L10.0609 15.8075V8.52112L16.3711 12.1643Z"
        fill="#FC6969"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
