<template>
  <div class="twoDepthWrap">
    <ul>
      <main-two-depth-item
        v-for="item in list"
        :key="item.title"
        :item="item"
      />
    </ul>
  </div>
</template>

<script>
import MainTwoDepthItem from "./MainTwoDepthItem.vue";
export default {
  components: { MainTwoDepthItem },
  props: {
    list: {
      type: Array[Object],
      id: Number,
      title: String,
      url: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.twoDepthWrap {
  position: absolute;
  top: 9px;
  right: 0;
  width: 50%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(30px);
  transition: 0.25s ease;
  padding-left: 20px;
  padding-right: 25px;
}
.active {
  .twoDepthWrap {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  }
}
</style>
