<template>
  <span class="icon">
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none">
      <path
        d="M16.5 8H22.75M17.75 13H22.75M20.25 18H22.75M20.25 24.25H7.75C2.75 24.25 1.5 23 1.5 18V8C1.5 3 2.75 1.75 7.75 1.75H20.25C25.25 1.75 26.5 3 26.5 8V18C26.5 23 25.25 24.25 20.25 24.25Z"
        class="stroke"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 18.4124C13.9159 17.535 13.5273 16.7146 12.9018 16.0936C12.2762 15.4726 11.453 15.0901 10.575 15.0124C9.94321 14.9499 9.30679 14.9499 8.675 15.0124C7.79782 15.0924 6.97598 15.4757 6.35087 16.0963C5.72575 16.7168 5.33647 17.5358 5.25 18.4124M9.625 12.1124C10.2251 12.1124 10.8005 11.874 11.2248 11.4497C11.6491 11.0254 11.8875 10.45 11.8875 9.8499C11.8875 9.24985 11.6491 8.67437 11.2248 8.25007C10.8005 7.82577 10.2251 7.5874 9.625 7.5874C9.02495 7.5874 8.44947 7.82577 8.02517 8.25007C7.60087 8.67437 7.3625 9.24985 7.3625 9.8499C7.3625 10.45 7.60087 11.0254 8.02517 11.4497C8.44947 11.874 9.02495 12.1124 9.625 12.1124V12.1124Z"
        class="stroke"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.stroke {
  stroke: #7a7a7a;
}
</style>
