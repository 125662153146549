/* home */
import { home, zoom } from ".";

function getHomeMyInfo() {
  return home.get("/consult/near");
}

function getHomeMyProfile() {
  return home.get("/my-info");
}

/* 의료진 */
const doctor = "/physician";

// 의료진 조회
function getHomeDoctor(query) {
  return home.get(`${doctor}${query}`);
}

// 의료진 프로필 조회
function getHomeDoctorProfile(id) {
  return home.get(`${doctor}/${id}`);
}

//의료진 검색
function getHomeDoctorSearch(obj) {
  return home.get(
    `${doctor}/${obj.type}/${obj.text}${obj.query}`
  );
}

/* 의료진의 스케쥴 날짜 목록 조회 */
function getHomeDoctorSked(url) {
  return home.get(
    `${doctor}/${url.id}/schedule${url.query}`
  );
}

/* 의료진의 스케쥴 날짜 상세 목록 조회 */
function getHomeDoctorSkedDetail(url) {
  return home.get(
    `${doctor}/${url.id}/schedule/${url.params}`
  );
}

/* 환자 상담 신청 */
function postHomeBooking(url) {
  return home.post(`/schedule/${url.params}`, url.data);
}

/* 환자 상담 신청 결제 */
function postHomeBookingPayment(url) {
  return home.post(
    `/schedule/${url.params}/writed`,
    url.query
  );
}

//줌
function getHomeZoom(query) {
  return zoom.get(`meeting/web-sdk/parameter${query}`);
}

//메타버스
function getHomeMetaverse(booking_sid) {
  return home.get(`metaverse/${booking_sid}`);
}

export {
  getHomeMyInfo,
  getHomeDoctorSked,
  getHomeDoctor,
  getHomeZoom,
  getHomeDoctorSkedDetail,
  getHomeDoctorProfile,
  postHomeBooking,
  postHomeBookingPayment,
  getHomeMyProfile,
  getHomeDoctorSearch,
  getHomeMetaverse,
};
