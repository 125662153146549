export function formatDate(value, type) {
  const date = new Date(value);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month > 9 ? month : `0${month}`;
  let day = date.getDate();
  day = day > 9 ? day : `0${day}`;
  let month_ = date.getMonth() + 1;

  switch (type) {
    case "type1":
      return `${year}/${month}/${day}`;
    case "type2":
      return `${year}-${month}-${day}`;
    case "type3":
      return `${month_}. ${day}.`;
    default:
      return `${year}. ${month}. ${day}`;
  }
}

export function formatTime(value, type) {
  const date = new Date(value);
  let hours = date.getHours();
  let hour = date.getHours();
  let seconds = date.getSeconds();
  hours = hours % 12 ? date.getHours() % 12 : 12;
  const minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let ampm = date.getHours() >= 12 ? "PM" : "AM";
  switch (type) {
    case "type1":
      return `${hour}:${minutes}:${seconds}`;
    default:
      return `${hours < 10 ? "0" + hours : hours}:${minutes} ${ampm}`;
  }
}

export function queryString(value) {
  let query = new URLSearchParams(value).toString();
  return `?${query}`;
}
