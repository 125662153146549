<template>
  <button class="langBtn" @click.prevent="toggle">
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4695 21.0049L18.9017 15.8809L16.334 21.0049M16.8019 20.0929H21.0255"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.41116 5.62H4.53965M18.9022 25C18.1016 24.9998 17.3088 24.842 16.5692 24.5354C15.8296 24.2289 15.1576 23.7796 14.5916 23.2133C14.0256 22.647 13.5767 21.9748 13.2704 21.235C12.9642 20.4952 12.8067 19.7023 12.8068 18.9016C12.807 18.1009 12.9648 17.3081 13.2714 16.5684C13.5779 15.8287 14.0271 15.1566 14.5933 14.5906C15.1596 14.0245 15.8317 13.5755 16.5715 13.2693C17.3112 12.963 18.104 12.8054 18.9046 12.8056C20.5215 12.8059 22.0721 13.4486 23.2152 14.5923C24.3583 15.7359 25.0003 17.2869 25 18.904C24.9997 20.5211 24.3571 22.0718 23.2135 23.215C22.0699 24.3582 20.5191 25.0003 18.9022 25ZM4.62364 1H9.32717C11.8109 1 13.0108 2.2 12.9508 4.624V9.328C13.0108 11.812 11.8109 13.012 9.32717 12.952H4.62364C2.19988 13 1 11.8 1 9.316V4.612C1 2.2 2.19988 1 4.62364 1Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.18698 5.60742C8.18698 7.70742 6.54314 9.41142 4.52734 9.41142"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.41116 9.412C8.53525 9.412 7.74333 8.944 7.19138 8.2M1 16.6C1 21.244 4.75562 25 9.39916 25L8.13929 22.9M24.9976 9.4C24.9976 4.756 21.242 1 16.5984 1L17.8583 3.1"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  methods: {
    toggle() {
      this.$emit("toggle");
    },
  },
};
</script>

<style></style>
