<template>
  <div>
    <Layout :title="title" :quickMenu="true">
      <template v-slot:content>
        <div class="errorContentW">
          <template v-if="isOnLine">
            <error-icon class="icon" />
            <h3 class="title" v-html="contentTitle"></h3>
            <p class="txt" v-html="txt"></p>
            <round-btn :text="btnTxt" class="errorBtn" link="/home" />
          </template>
          <template v-if="!isOnLine">
            <wifi-icon class="icon" />
            <p class="wifiTxt" v-html="wifiContent" />
            <round-btn
              :text="wifiTxt"
              class="errorBtn"
              @click.prevent="retry"
            />
          </template>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from "@/components/common/styled/Layout.vue";
import ErrorIcon from "@/components/common/styled/icon/error/ErrorIcon.vue";
import RoundBtn from "@/components/common/styled/btn/RoundBtn.vue";
import WifiIcon from "@/components/common/styled/icon/input/WifiIcon.vue";

export default {
  components: { Layout, ErrorIcon, RoundBtn, WifiIcon },
  data() {
    return {
      isOnLine: navigator.onLine,
      title: this.$i18n.t("title"),
      contentTitle: this.$i18n.t("errer.contentTitle"),
      txt: this.$i18n.t("errer.txt"),
      btnTxt: this.$i18n.t("errer.btnTxt"),
      wifiTxt: this.$i18n.t("errer.wifiTxt"),
      wifiContent: this.$i18n.t("errer.wifiContent"),
    };
  },
  mounted() {
    this.$store.state.loading = false;
  },
  methods: {
    retry() {
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss">
.errorContentW {
  padding: 0 15px;
  display: flex;
  min-height: calc(100vh - 171px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    display: flex;
    margin-bottom: 41px;
  }
  .title {
    text-align: center;
    margin-bottom: 16px;
    @include boxTxtStyle(
      $fz: 40px,
      $fw: 700,
      $lh: 54px,
      $ls: -0.9px,
      $c: #393a3e
    );
    .dib {
      display: inline-block;
    }
  }
  .txt {
    text-align: center;
    @include boxTxtStyle(
      $fz: 16px,
      $fw: 700,
      $lh: 21px,
      $ls: -0.9px,
      $c: #393a3e
    );
  }
  .wifiTxt {
    text-align: center;
    @include boxTxtStyle(
      $fz: 20px,
      $fw: 700,
      $lh: 27px,
      $ls: -0.9px,
      $c: #393a3e
    );
  }
  .errorBtn {
    margin-top: 47px;
    min-width: 220px;
  }
}
</style>
