<template>
  <button :class="type === 'red' ? 'iconWrap red' : 'iconWrap'">
    <slot name="icon"></slot>
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #1fc29d;
  &:active,
  &:focus {
    background-color: #fc6969;
  }
}
</style>
