<template>
  <span class="icon">
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path
        d="M7.94444 1V4.75M19.0556 1V4.75M1.69444 9.8625H25.3056M26 9.125V19.75C26 23.5 23.9167 26 19.0556 26H7.94444C3.08333 26 1 23.5 1 19.75V9.125C1 5.375 3.08333 2.875 7.94444 2.875H19.0556C23.9167 2.875 26 5.375 26 9.125Z"
        class="stroke"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.2513 16H18.2625M18.2513 19.75H18.2625M13.6263 16H13.6388M13.6263 19.75H13.6388M9 16H9.0125M9 19.75H9.0125"
        class="stroke"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.icon {
  .stroke {
    stroke: #b0b0b1;
  }
}

.active {
  .icon {
    .stroke {
      animation: fade 0.25s linear alternate;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fade {
  from {
    stroke: #b0b0b1;
  }
  to {
    stroke: $mainColor;
  }
}
</style>
