import { validateEmail, validatePassword } from "@/utils/validation";

export const date = {
  methods: {
    year(date) {
      return date.getFullYear();
    },
    month(date) {
      return date.getMonth() + 1;
    },
    date(date) {
      return date.getDate();
    },
    ampm(date) {
      return date.getHours() >= 12 ? "PM" : "AM";
    },
    hours(date) {
      return date.getHours() % 12
        ? date.getHours() % 12 < 10
          ? "0" + (date.getHours() % 12)
          : date.getHours() % 12
        : 12;
    },
    minutes(date) {
      return date.getMinutes() < 10
        ? "0" + date.getMinutes()
        : date.getMinutes();
    },
    ampm_(date) {
      return date.substr(0, 2) >= 12 ? "PM" : "AM";
    },
    ampmT(date) {
      let time =
        date.substr(0, 2) - 12 > 0 ? date.substr(0, 2) - 12 : date.substr(0, 2);
      const time_ = date.substr(0, 1) !== "0" && time < 10 ? `0${time}` : time;
      return `${time_}:00`;
    },
    day(date) {
      let week = [];
      switch (this.$store.state.lang) {
        case "ko":
          week = ["일", "월", "화", "수", "목", "금", "토"];
          break;
        case "ru":
          week = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
          break;
        default:
          week = ["S", "M", "T", "W", "T", "F", "S"];
          break;
      }
      return week[new Date(date).getDay()];
    },
  },
};
export const email = {
  data() {
    return {
      email: null,
      patient_manager_email: null,
      physician_manager_email: null,
      consult_manager_email: null,
      loginEmailMsg: `5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만
사용 가능합니다.`,
    };
  },
  computed: {
    isEmailValid() {
      return validateEmail(this.email) ? null : this.loginEmailMsg;
    },
    ManagerEmailValid() {
      return validateEmail(this.patient_manager_email)
        ? null
        : this.loginEmailMsg;
    },
    fixManagerEmailValid() {
      return validateEmail(this.consult_manager_email)
        ? null
        : this.loginEmailMsg;
    },
  },
};

export const password = {
  data() {
    return {
      newPw: null,
      password: null,
      loginPwMsg: "8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.",
    };
  },
  computed: {
    isPwValid() {
      return validatePassword(this.password) ? null : this.loginPwMsg;
    },
    isNewPwValid() {
      return validatePassword(this.newPw) ? null : this.loginPwMsg;
    },
  },
};

export const textHtml = {
  methods: {
    txtChange(value) {
      return value?.split("\n").join("<br>");
    },
  },
};

export const errorHendler = {
  methods: {
    errorHendler(error) {
      if (error === -968 || error === -967) {
        this.$store.state.reLogin = true;
        this.$store.state.error = error;
      }
      if (error === -999) {
        this.$router.push("/error");
      }
    },
  },
};

export const utc = {
  data() {
    return {
      time: null,
      diff: null,
    };
  },
  methods: {
    utc(time) {
      return time.getTime() + time.getTimezoneOffset() * 60 * 1000;
    },
    timeDrff(time) {
      return time * 60 * 60 * 1000;
    },
    localTime() {
      new Date(this.utc() + this.localTime());
    },
  },
};
