<template>
  <div class="langMenuWrap" v-if="open">
    <div class="wrap">
      <h2 class="title">{{ title }}</h2>
      <ul class="langList">
        <li class="list" v-for="item in list" :key="item.type">
          <button class="langBtn" @click="langChange(item.type)">
            {{ item.text }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { saveLangLocalStorage } from "@/utils/localStorage";
export default {
  props: {
    langList: Object,
  },
  data() {
    return {
      open: false,
      list: this.langList.list,
      title: this.langList.title,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    langChange(lang) {
      this.$store.state.lang = lang;
      this.$i18n.locale = lang;
      saveLangLocalStorage(lang);
      this.$router.go(0);
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" scoped>
.langMenuWrap {
  position: absolute;
  top: 84px;
  right: 11px;
  text-align: left;
  z-index: 10;
  .wrap {
    padding: 19px 20px;
    min-width: 159px;
    background: #ffffff;
    border: 1px solid #dadada;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    .title {
      padding-bottom: 11px;
      border-bottom: 1px solid #979797;
      @include boxTxtStyle($fw: 500, $fz: 21px, $lh: 32px, $c: #0a3d3a);
    }
    .langList {
      padding: 12px 0 0 12px;
    }
    .list {
      margin-bottom: 10px;
    }
    .langBtn {
      position: relative;
      width: 100%;
      text-align: left;
      @include boxTxtStyle($fw: 500, $fz: 16px, $lh: 32px, $c: #7a7a7a);
      &:focus::after,
      &:active::after {
        @include pseudo($t: 9px, $r: 0);
        width: 18px;
        height: 14px;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.10003 8.1001C6.94023 9.2599 5.05982 9.2599 3.90003 8.1001L2.50003 6.7001C2.11343 6.3135 1.48662 6.3135 1.10002 6.7001C0.713425 7.0867 0.713425 7.7135 1.10002 8.1001L3.8787 10.8788C5.05028 12.0504 6.94977 12.0504 8.12135 10.8788L17.3 1.7001C17.6866 1.3135 17.6866 0.686698 17.3 0.300099C16.9134 -0.0865012 16.2866 -0.0865011 15.9 0.300099L8.10003 8.1001Z' fill='%23199A8E'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat no-repeat;
        background-position: center center;
      }
    }
  }
}
</style>
