<template>
  <button>
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9855 10H1.68115"
        stroke="#E9ECFF"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.83333 19L1.68115 10L9.83333 1"
        stroke="#E9ECFF"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span class="h">{{ hideTxt }}</span>
  </button>
</template>

<script>
export default {
  props: {
    hideTxt: String,
  },
};
</script>

<style></style>
