<template>
  <span class="icon">
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 20.9931V17.238M9.775 2.02964L3.0375 7.28685C1.9125 8.16305 1 10.0281 1 11.4425V20.7178C1 23.6217 3.3625 26 6.2625 26H20.7375C23.6375 26 26 23.6217 26 20.7303V11.6178C26 10.1032 24.9875 8.16305 23.75 7.29937L16.025 1.87944C14.275 0.652755 11.4625 0.71534 9.775 2.02964Z"
        class="stroke"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.icon {
  .stroke {
    stroke: #b0b0b1;
  }
}

.active {
  .icon {
    .stroke {
      animation: fade 0.25s linear alternate;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fade {
  from {
    stroke: #b0b0b1;
  }
  to {
    stroke: $mainColor;
  }
}
</style>
