<template>
  <h1 class="logo">
    <span class="h">{{ $t("title") }}</span>
    <svg width="209" height="48" viewBox="0 0 178 41" fill="none">
      <path
        d="M133.032 13.947C132.888 12.4987 132.249 11.3468 131.114 10.4913C129.979 9.63586 128.552 9.20814 126.832 9.20814C125.621 9.20814 124.554 9.41565 123.631 9.83067C122.716 10.2457 121.996 10.8216 121.471 11.5585C120.954 12.2869 120.696 13.117 120.696 14.0487C120.696 14.7347 120.844 15.3361 121.141 15.8527C121.437 16.3694 121.835 16.8141 122.335 17.1867C122.843 17.5509 123.402 17.8643 124.012 18.1269C124.63 18.3895 125.253 18.6097 125.88 18.7875L128.624 19.5752C129.454 19.8039 130.284 20.0961 131.114 20.4519C131.944 20.8076 132.702 21.2565 133.388 21.7986C134.083 22.3322 134.637 22.9886 135.052 23.7678C135.476 24.5386 135.688 25.466 135.688 26.5502C135.688 27.9392 135.328 29.1885 134.608 30.2981C133.888 31.4076 132.855 32.2885 131.508 32.9407C130.161 33.5844 128.548 33.9062 126.667 33.9062C124.897 33.9062 123.36 33.614 122.055 33.0296C120.751 32.4367 119.726 31.6194 118.981 30.5776C118.236 29.5358 117.816 28.3288 117.723 26.9567H120.162C120.247 27.9816 120.586 28.8497 121.179 29.5612C121.772 30.2727 122.543 30.8147 123.491 31.1874C124.44 31.5516 125.498 31.7337 126.667 31.7337C127.963 31.7337 129.115 31.5177 130.123 31.0858C131.139 30.6453 131.936 30.0355 132.512 29.2563C133.096 28.4686 133.388 27.5538 133.388 26.5121C133.388 25.6312 133.159 24.8943 132.702 24.3014C132.245 23.7001 131.605 23.1961 130.784 22.7896C129.971 22.383 129.026 22.023 127.951 21.7096L124.838 20.7949C122.797 20.1851 121.213 19.3423 120.086 18.2666C118.96 17.191 118.397 15.8189 118.397 14.1503C118.397 12.7528 118.769 11.5246 119.515 10.4659C120.268 9.39871 121.285 8.56866 122.564 7.97577C123.851 7.37441 125.295 7.07373 126.896 7.07373C128.514 7.07373 129.945 7.37018 131.19 7.96307C132.435 8.55596 133.422 9.3733 134.15 10.4151C134.887 11.4484 135.281 12.6257 135.332 13.947H133.032Z"
        fill="white"
      />
      <path d="M112.645 7.81885V33.8383H110.27V7.81885H112.645Z" fill="white" />
      <path
        d="M168.685 7.81885V33.8383H166.309V7.81885H168.685Z"
        fill="#96F3EB"
      />
      <path
        d="M177.645 7.81885V33.8383H175.27V7.81885H177.645Z"
        fill="#96F3EB"
      />
      <path
        d="M87.1641 33.8383V7.81885H95.5619C97.4084 7.81885 98.9499 8.15341 100.186 8.82253C101.432 9.48317 102.367 10.4022 102.994 11.5795C103.629 12.7483 103.947 14.0908 103.947 15.6069C103.947 17.123 103.629 18.4612 102.994 19.6216C102.367 20.782 101.436 21.6882 100.199 22.3404C98.9626 22.9926 97.4295 23.3187 95.6 23.3187H94.05V21.1462H95.5365C96.8917 21.1462 98.0182 20.9217 98.916 20.4728C99.8223 20.0239 100.496 19.3844 100.936 18.5544C101.385 17.7243 101.609 16.7418 101.609 15.6069C101.609 14.4719 101.385 13.4809 100.936 12.634C100.487 11.7785 99.8096 11.1179 98.9033 10.652C98.0055 10.1862 96.8705 9.95325 95.4984 9.95325H89.5399V33.8383H87.1641ZM98.6365 22.099L105.04 33.8383H102.295L95.9558 22.099H98.6365Z"
        fill="white"
      />
      <path
        d="M78.4187 7.07373H80.7945V24.3014C80.7945 26.0716 80.3795 27.6555 79.5494 29.053C78.7194 30.4421 77.5632 31.5389 76.081 32.3436C74.6073 33.1397 72.8921 33.5378 70.9356 33.5378C68.9875 33.5378 67.2724 33.1355 65.7901 32.3308C64.3079 31.5262 63.1518 30.4294 62.3217 29.0403C61.4917 27.6513 61.0767 26.0716 61.0767 24.3014V7.07373H63.4525V24.1363C63.4525 25.5168 63.7616 26.7492 64.3799 27.8334C64.9982 28.909 65.8664 29.756 66.9844 30.3743C68.1109 30.9926 69.4279 31.3018 70.9356 31.3018C72.4432 31.3018 73.7603 30.9926 74.8868 30.3743C76.0133 29.756 76.8814 28.909 77.4912 27.8334C78.1095 26.7492 78.4187 25.5168 78.4187 24.1363V7.07373Z"
        fill="white"
      />
      <path
        d="M143.334 33.8383H140.844L150.309 7.81885H152.85L162.315 33.8383H159.825L151.668 10.9061H151.49L143.334 33.8383Z"
        fill="#96F3EB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M55.8593 7.07373C48.4161 7.26579 42.4429 13.1968 42.4429 20.4852C42.4429 27.7736 48.4161 33.7046 55.8593 33.8966V31.6596C49.6512 31.4693 44.6789 26.5392 44.6789 20.4851C44.6789 14.431 49.6512 9.50085 55.8593 9.31058V7.07373Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.9275 0.783369L31.9275 28.0386C28.1778 28.5704 24.1428 28.8597 19.9393 28.8598L19.9393 14.4299L19.9393 6.48534L12.6687 9.89806L-7.19931e-07 11.0359L19.7948 0.000617311L19.9393 0.0099349L19.9393 -5.21881e-07C23.9525 7.01043e-06 27.8123 0.26376 31.4167 0.750415L31.9275 0.783369Z"
        fill="#96F3EB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.396316 26.2966L0.396314 12.688C4.14566 12.1564 8.18034 11.8671 12.3834 11.867L12.3834 26.2966L0.396316 26.2966ZM0.39632 39.9059L0.396316 26.2969L12.385 26.2969L12.385 33.5632L19.7947 30.0893L32.0674 29.2975L12.4745 40.7262L12.385 40.6903L12.385 40.7271C8.18135 40.727 4.14612 40.4377 0.39632 39.9059Z"
        fill="white"
      />
    </svg>
  </h1>
</template>

<script>
export default {};
</script>

<style></style>
