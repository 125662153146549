<template>
  <div class="layout" :class="type">
    <app-header :title="title" :main="main" />
    <section :class="type">
      <slot name="content"></slot>
      <quick-menu v-if="quickMenu" />
    </section>
  </div>
</template>

<script>
import AppHeader from "../header/AppHeader.vue";
import QuickMenu from "../quickmenu/QuickMenu.vue";
export default {
  components: { AppHeader, QuickMenu },
  props: {
    main: Boolean,
    title: String,
    type: String,
    quickMenu: Boolean,
  },
};
</script>

<style lang="scss" scoped>
body {
}
.layout {
  min-height: 100vh;
  padding-bottom: 74px;
  background: #fff;
  &.main {
  }
  .main {
  }
  &.pdn {
    padding-bottom: 0;
  }
  .sub {
    padding: 19px 15px 51px;
  }
  //login & register form layout
  .form {
    padding: 27px 24px;
  }
  &.form {
    padding-bottom: 0;
  }
}
</style>
