import store from "@/store/index";
import { userPoolData } from "@/utils/common";
import {
  getAuthFromLocalStorage,
  getAuthRefreshTokenLocalStorage,
  getUserFromLocalStorage,
  saveAuthToLocalStorage,
} from "@/utils/localStorage";
import axios from "axios";
var AmazonCognitoIdentity = require("amazon-cognito-identity-js");
axios.defaults.withCredentials = true;

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    function (config) {
      const token = getAuthFromLocalStorage();
      if (token) {
        config.headers["access_token"] = token;
        config.headers["time_zone"] =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        config.headers["service_lang"] = store.state.lang;
      }
      config.headers = config.headers ?? {};
      if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      } else {
        config.headers["Content-Type"] = "application/json";
      }
      config.headers.Authorization = token;
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.timeout = 12000;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const authRefreshToken = getAuthRefreshTokenLocalStorage();
      const originalRequest = error.config;
      if (
        (error.response.data.code === -968 ||
          error.response.data.code === -967) &&
        !originalRequest._retry &&
        authRefreshToken
      ) {
        const refresh = (cognitoUser, token) => {
          return new Promise((resolve, reject) => {
            cognitoUser.refreshSession(token, (err, session) => {
              originalRequest._retry = true;
              if (err) {
                reject(err);
                store.state.reLogin = true;
              } else {
                saveAuthToLocalStorage(session.getAccessToken().getJwtToken());
                error.config["access_token"] = getAuthFromLocalStorage();
                return resolve(instance(error.config));
              }
            });
          });
        };
        // eslint-disable-next-line
        async function refreshToken(user, token) {
          try {
            const userData = {
              Username: getUserFromLocalStorage(),
              Pool: new AmazonCognitoIdentity.CognitoUserPool(userPoolData),
            };
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

            const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
              RefreshToken: authRefreshToken,
            });
            return await refresh(cognitoUser, refreshToken);
          } catch (error) {
            store.state.reLogin = true;
          }
        }

        return await refreshToken();
      }
      if (error.code === "ECONNABORTED" || error.response.data.code === -803) {
        store.state.reLogin = true;
      } else {
        return Promise.reject(error);
      }
    }
  );
  return instance;
}
