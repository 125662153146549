<template>
  <header :class="!main && 'subHeader'">
    <!-- main menu -->
    <div v-if="main" class="mainMenu">
      <div class="mainHeaderWrap">
        <header-main-logo />
        <menu-btn
          class="menuBtn"
          :hideTxt="$t('header.menuOpen')"
          @click="menuOpen"
        />
        <lang-select-btn @toggle="langMenuToggle" class="langMenuBtn" />
        <lang-menu :langList="langList" ref="langMenu" />
      </div>
      <main-title :txt="txt" :title="headertitle" :placeHolder="placeHolder" />
      <consult-time />
      <Transition>
        <main-menu v-if="menu" @close="menuClose" />
      </Transition>
    </div>
    <!-- sub menu -->
    <div v-else class="subMenu">
      <h2 class="headerTitle">{{ title }}</h2>
      <left-arrow class="backBtn" :hideTxt="$t('header.back')" @click="back" />
    </div>
  </header>
</template>

<script>
import { errorHendler } from "@/mixins/ListMixin";
import HeaderMainLogo from "../logo/HeaderMainLogo.vue";
import LangMenu from "../menu/LangMenu.vue";
import MainMenu from "../menu/MainMenu.vue";
import LangSelectBtn from "../styled/btn/LangSelectBtn.vue";
import MenuBtn from "../styled/btn/MenuBtn.vue";
import LeftArrow from "../styled/icon/header/LeftArrow.vue";
import ConsultTime from "./ConsultTime.vue";
import MainTitle from "./MainTitle.vue";
export default {
  components: {
    LeftArrow,
    MenuBtn,
    MainMenu,
    HeaderMainLogo,
    MainTitle,
    LangSelectBtn,
    LangMenu,
    ConsultTime,
  },
  props: {
    main: Boolean,
    title: String,
  },
  data() {
    return {
      txt: this.$i18n.t("header.search.txt"),
      headertitle: this.$i18n.t("header.search.headertitle"),
      placeHolder: this.$i18n.t("header.search.placeHolder"),
      menu: false,
      langList: {
        title: this.$i18n.t("common.select"),
        list: [
          { type: "ko", text: this.$i18n.t("header.lang.list[0].text") },
          { type: "en", text: this.$i18n.t("header.lang.list[1].text") },
          { type: "ru", text: this.$i18n.t("header.lang.list[2].text") },
        ],
      },
    };
  },
  created() {
    if (this.main) {
      this.getMyProfile();
    }
  },
  mixins: [errorHendler],
  methods: {
    back() {
      if (this.$store.state.token) {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    menuOpen() {
      this.menu = true;
    },
    menuClose() {
      this.menu = false;
    },
    langMenuToggle() {
      this.$refs.langMenu.toggle();
    },
    async getMyProfile() {
      try {
        this.$store.state.loading = true;
        await this.$store.dispatch("HOME_MY_PROFILE");
        this.$store.state.loading = false;
        return;
      } catch (error) {
        this.$store.state.loading = false;
        this.errorHendler(error.response.data.code);
      }
    },
  },
};
</script>

<style lang="scss">
header {
  position: relative;
  text-align: center;
  z-index: 101;
  .mainHeaderWrap {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 314px;
      content: "";
      z-index: -1;
      display: block;
      background-color: $mainColor;
      clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 0% 100%);
    }
  }
  .subMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $mainColor;
    height: 65px;
    z-index: 50;
    .headerTitle {
      @include boxTxtStyle($fw: 900, $fz: 20px, $lh: 27px, $c: #e9ecff);
    }
    .backBtn {
      position: absolute;
      left: 12px;
      top: 50%;
      margin-top: -7px;
    }
    .popupIcon {
      position: absolute;
      right: 12px;
      bottom: 28px;
    }
  }
  .langMenuBtn {
    position: absolute;
    top: 45px;
    right: 19px;
  }
  .mainMenu {
    position: relative;
    .logo {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-top: 37px;
      justify-content: center;
      h1 {
        margin-left: 4px;
      }
    }
    .menuBtn {
      position: absolute;
      top: 50px;
      left: 16px;
    }
  }
  .v-enter-active,
  .v-leave-active {
    transition: 0.5s ease;
    .menuWrap {
      transform: translateX(0);
      transition: transform 0.5s ease;
    }
    .bg {
      opacity: 1;
      transition: opacity 0.5s ease;
    }
  }

  .v-enter-from,
  .v-leave-to {
    transition: 0.5s ease;
    .menuWrap {
      transform: translateX(-100%);
      transition: transform 0.5s ease;
    }
    .bg {
      opacity: 0;
      transition: opacity 0.5s ease;
    }
  }
}
</style>
